import { useState, useEffect, useRef } from 'react';

export const useSchedulePicker = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scheduleTime, setScheduleTime] = useState('Immediately');
  const [selectedDate, setSelectedDate] = useState<string>('Immediately');
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [stagedDate, setStagedDate] = useState<string>('Immediately');
  const [stagedTime, setStagedTime] = useState<string | null>(null);
  const [shouldOpenUpward, setShouldOpenUpward] = useState(false);
  const [minTime, setMinTime] = useState('');
  const popupRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const getDateOptions = () => {
    const options = [];
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    options.push({
      label: `Today - ${today.toLocaleDateString()}`,
      value: today.toISOString().split('T')[0],
    });
    options.push({
      label: `Tomorrow - ${tomorrow.toLocaleDateString()}`,
      value: tomorrow.toISOString().split('T')[0],
    });

    for (let i = 2; i <= 6; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
      const weekdayName = futureDate.toLocaleDateString(undefined, {
        weekday: 'long',
      });
      options.push({
        label: `${weekdayName} - ${futureDate.toLocaleDateString()}`,
        value: futureDate.toISOString().split('T')[0],
      });
    }
    return options;
  };

  useEffect(() => {
    if (popupRef.current && parentRef.current) {
      const popupRect = popupRef.current.getBoundingClientRect();
      const parentRect = parentRef.current.getBoundingClientRect();
      const availableSpaceBelow = window.innerHeight - parentRect.bottom;
      const availableSpaceAbove = parentRect.top;
      const popupHeight = popupRect.height;

      if (
        popupHeight > availableSpaceBelow &&
        availableSpaceAbove > popupHeight
      ) {
        setShouldOpenUpward(true);
      } else {
        setShouldOpenUpward(false);
      }
    }
  }, [isOpen]);

  const handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const date = e.target.value;
    setStagedDate(date);
    if (date === 'Immediately') {
      setStagedTime(null);
    }
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStagedTime(e.target.value);
  };

  useEffect(() => {
    if (stagedDate === new Date().toISOString().split('T')[0]) {
      const now = new Date();
      const currentHours = String(now.getHours()).padStart(2, '0');
      const currentMinutes = String(now.getMinutes()).padStart(2, '0');
      setMinTime(`${currentHours}:${currentMinutes}`);
    } else {
      setMinTime('');
    }
  }, [stagedDate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle confirming the date and time
  const handleConfirm = () => {
    if (stagedDate !== 'Immediately' && !stagedTime) {
      alert('Please select a time');
    } else {
      setSelectedDate(stagedDate);
      setSelectedTime(stagedTime);
      setScheduleTime(
        stagedDate === 'Immediately'
          ? 'Immediately'
          : `${stagedDate} ${stagedTime}`,
      );
      setIsOpen(false);
    }
  };

  return {
    isOpen,
    scheduleTime,
    selectedDate,
    selectedTime,
    stagedDate,
    stagedTime,
    shouldOpenUpward,
    minTime,
    popupRef,
    parentRef,
    getDateOptions,
    handleDateChange,
    handleTimeChange,
    handleConfirm,
    setIsOpen,
  };
};
