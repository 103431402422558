import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from './useUserContext';

const PublicRoute: React.FC = () => {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner component
  }

  return user.isAuthenticated ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
