import { useState } from 'react';
import { useUser } from '../components/useUserContext';
import { toast } from 'react-toastify';

export const useAccount = () => {
  const {
    user,
    updateEmail: superTokensUpdateEmail,
    updatePassword: superTokensUpdatePassword,
  } = useUser();

  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = async (newEmail: string, password: string) => {
    if (!password) {
      toast.error('Password is required');
      return;
    }

    setIsLoading(true);
    try {
      await superTokensUpdateEmail(newEmail, password);
      toast.success('Email updated successfully');
      setEmailModalOpen(false);
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : 'Failed to update email',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordChange = async (
    oldPassword: string,
    newPassword: string,
  ) => {
    setIsLoading(true);
    try {
      await superTokensUpdatePassword(oldPassword, newPassword);
      toast.success('Password updated successfully');
      setPasswordModalOpen(false);
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : 'Failed to update password',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    user,
    isEmailModalOpen,
    setEmailModalOpen,
    isPasswordModalOpen,
    setPasswordModalOpen,
    handleEmailChange,
    handlePasswordChange,
    isLoading,
  };
};
