import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  ReactNode,
} from 'react';

import {
  signIn,
  signOut,
  sendVerificationEmail,
  sessionExists,
  updateEmail as superTokensUpdateEmail,
  updatePassword as superTokensUpdatePassword,
} from '../services/SuperTokensService';
import {
  changeEmail as userChangeEmail,
  deleteUser,
  getUserInfo,
} from '../services/UserService';

interface User {
  isAuthenticated: boolean;
  userId: string | null;
  username: string;
  email: string;
  emailVerified: boolean;
  platforms: {
    youtube: boolean;
    vimeo: boolean;
    x: boolean;
  };
}

interface UserContextType {
  user: User;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  changeEmail: (email: string, password: string) => Promise<void>;
  deleteAccount: () => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
  updateEmail: (newEmail: string, password: string) => Promise<void>;
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User>({
    isAuthenticated: false,
    userId: null,
    username: '',
    email: '',
    emailVerified: false,
    platforms: {
      youtube: false,
      vimeo: false,
      x: false,
    },
  });
  const [isLoading, setIsLoading] = useState(true);

  const backendApiUrl = process.env.REACT_APP_API_URL;

  const checkAuthStatus = useCallback(async () => {
    try {
      const session = await sessionExists();
      if (session) {
        const userId = await getUserInfo().then((userInfo) => userInfo.userId);
        const response = await fetch(`${backendApiUrl}/user`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const userData = await response.json();

        setUser({
          isAuthenticated: true,
          userId,
          username: userData.username || '',
          email: userData.email || '',
          emailVerified: userData.emailVerified || false,
          platforms: userData.platforms || {
            youtube: false,
            vimeo: false,
            x: false,
          },
        });
      } else {
        setUser({
          isAuthenticated: false,
          userId: null,
          username: '',
          email: '',
          emailVerified: false,
          platforms: {
            youtube: false,
            vimeo: false,
            x: false,
          },
        });
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = async (email: string, password: string) => {
    await signIn(email, password);
    checkAuthStatus();
  };

  const logout = async () => {
    await signOut();
    window.location.href = '/';
  };

  const changeEmail = async (email: string, password: string) => {
    try {
      await userChangeEmail(email);
      checkAuthStatus();
    } catch (error) {
      if (error instanceof Error) {
        console.error('Change Email Error:', error.message);
      } else {
        console.error('Change Email Error:', error);
      }
    }
  };

  const deleteAccount = async () => {
    try {
      await deleteUser();
      logout();
    } catch (error) {
      if (error instanceof Error) {
        console.error('Delete Account Error:', error.toString());
      } else {
        console.error('Delete Account Error:', String(error));
      }
    }
  };

  const resendVerificationEmail = async () => {
    await sendVerificationEmail();
  };

  const updateEmail = async (
    newEmail: string,
    password: string,
  ): Promise<void> => {
    const response = await superTokensUpdateEmail(newEmail, password);
    if (response.status === 'OK') {
      setUser((prev) => ({
        ...prev,
        email: newEmail,
      }));
    } else {
      throw new Error('Failed to update email');
    }
  };

  const updatePassword = async (oldPassword: string, newPassword: string) => {
    const response = await superTokensUpdatePassword(oldPassword, newPassword);
    if (response.status !== 'OK') {
      throw new Error('Failed to update password');
    }
  };

  const value: UserContextType = {
    user,
    isLoading,
    login,
    logout,
    changeEmail,
    deleteAccount,
    resendVerificationEmail,
    updateEmail,
    updatePassword,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
