import React, { useState } from 'react';
import { ChevronDown, ArrowUp, ArrowDown } from 'lucide-react';
import { useSortBar } from '../hooks/useSortBar';

const MobileSortBar: React.FC = () => {
  const { icons, handleIconClick, getSortStatus } = useSortBar();
  const [isOpen, setIsOpen] = useState(false);

  const sortOptions = icons.filter(
    (icon) => icon.id !== 'divider' && icon.id !== 'Search',
  );

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (id: string) => {
    handleIconClick(id);
    setIsOpen(false);
  };

  const selectedOption =
    sortOptions.find((option) => getSortStatus(option.id).isSelected) ||
    sortOptions[0];
  const { sortDescending } = getSortStatus(selectedOption.id);

  const toggleOrder = () => {
    handleIconClick(selectedOption.id);
  };

  return (
    <div className="relative flex items-center h-10">
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between px-3 h-full text-left bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-l-md shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none"
      >
        <div className="flex items-center">
          {selectedOption.IconComponent && (
            <selectedOption.IconComponent className="w-4 h-4 mr-2 text-midnight-coal dark:text-soft-cloud" />
          )}
          <span>{selectedOption.id}</span>
        </div>
        <ChevronDown className="w-4 h-4 ml-2 text-midnight-coal dark:text-soft-cloud" />
      </button>
      <button
        onClick={toggleOrder}
        className="h-full px-2 bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-r-md shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none border-l border-gray-200 dark:border-gray-600"
      >
        {sortDescending ? (
          <ArrowDown className="w-4 h-4" />
        ) : (
          <ArrowUp className="w-4 h-4" />
        )}
      </button>
      {isOpen && (
        <div className="absolute z-10 top-full left-0 mt-1 bg-soft-cloud dark:bg-darker-gray rounded-md shadow-lg">
          {sortOptions.map((option) => (
            <div
              key={option.id}
              className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
              onClick={() => handleSelect(option.id)}
            >
              {option.IconComponent && (
                <option.IconComponent className="w-4 h-4 mr-2 text-midnight-coal dark:text-soft-cloud" />
              )}
              <span className="text-midnight-coal dark:text-soft-cloud whitespace-nowrap">
                {option.id}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileSortBar;
