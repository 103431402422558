import { useState } from 'react';
import { ShieldCheck, EyeOff, Earth } from 'lucide-react';

export const useVisibility = () => {
  const [selectedVisibility, setSelectedVisibility] =
    useState<string>('unlisted');

  const visibilityOptions = [
    { id: 'private', label: 'Private', Icon: ShieldCheck },
    { id: 'public', label: 'Public', Icon: Earth },
    { id: 'unlisted', label: 'Unlisted', Icon: EyeOff },
  ];

  const handleVisibilityChange = (visibility: string) => {
    setSelectedVisibility(visibility);
  };

  return {
    selectedVisibility,
    visibilityOptions,
    handleVisibilityChange,
  };
};
