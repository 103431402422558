import { useLocation } from 'react-router-dom';
import {
  LayoutGrid,
  FileVideo,
  BarChartBig,
  MessageCircleMore,
  Settings,
  MessageSquareWarning,
  TicketCheck,
} from 'lucide-react';
import { validRoutes } from '../App';

export const useSidebar = () => {
  const location = useLocation();

  const icons = [
    { id: 'Dashboard', path: '/dashboard', IconComponent: LayoutGrid },
    { id: 'Videos', path: '/videos', IconComponent: FileVideo },
    { id: 'Analytics', path: '/analytics', IconComponent: BarChartBig },
    { id: 'Comments', path: '/comments', IconComponent: MessageCircleMore },
    { id: 'Platforms', path: '/platforms', IconComponent: TicketCheck },
    { id: 'Settings', path: '/settings', IconComponent: Settings },
    { id: 'Alerts', path: '/alerts', IconComponent: MessageSquareWarning },
  ];

  const getActiveStatus = (path: string) => location.pathname === path;

  const isRouteValid = (path: string) => validRoutes.includes(path);

  return { icons, getActiveStatus, isRouteValid };
};
