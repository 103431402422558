import { useState } from 'react';

export const useTags = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [menuTagIndex, setMenuTagIndex] = useState<number | null>(null);
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  // Handle adding tags (both for typing and pasting)
  const addTags = (newTags: string[]) => {
    const uniqueTags = newTags.filter((tag) => !tags.includes(tag));
    if (uniqueTags.length > 0) {
      setTags([...tags, ...uniqueTags]);
    }
    setInputValue(''); // Clear input value after adding tags
  };

  // Handle keydown event for "Enter" or ","
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue && !tags.includes(trimmedValue)) {
        addTags([trimmedValue]);
      }
    }
  };

  // Handle paste event
  const handlePaste = (pastedData: string) => {
    const pastedTags = pastedData
      .split(',')
      .map((tag) => tag.trim())
      .filter((tag) => tag && !tags.includes(tag)); // Ensure no empty tags or duplicates
    addTags(pastedTags);
    setInputValue(''); // Clear the input value after pasting
  };

  // Handle clicking on a tag to open the menu
  const handleTagClick = (index: number) => {
    setMenuTagIndex(menuTagIndex === index ? null : index);
  };

  // Edit tag logic (simple example to replace tag with "Edited")
  const editTag = (index: number) => {
    const editedTags = [...tags];
    editedTags[index] = 'Edited';
    setTags(editedTags);
  };

  // Remove tag logic
  const removeTag = (tagToRemove: string) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  return {
    tags,
    inputValue,
    setInputValue,
    handleKeyDown,
    handlePaste,
    handleTagClick,
    removeTag,
    editTag,
    menuTagIndex,
    hoveredIcon,
    setHoveredIcon,
  };
};
