import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../components/useUserContext';

const NotFoundPage: React.FC = () => {
  const { user } = useUser();

  return (
    <div className="flex items-center justify-center min-h-screen bg-anti-flash-white dark:bg-dark-gray">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-midnight-coal dark:text-cadet-gray mb-4">
          404
        </h1>
        <p className="text-xl text-midnight-coal dark:text-cadet-gray mb-8">
          Oops! Page not found.
        </p>
        <Link
          to={user.isAuthenticated ? '/dashboard' : '/login'}
          className="px-6 py-3 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-lg font-medium hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-300"
        >
          {user.isAuthenticated ? 'Go to Dashboard' : 'Log in'}
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
