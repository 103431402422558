import React, { useState } from 'react';
import { LayoutGrid, List } from 'lucide-react';

interface ViewBarProps {
  activeView: string;
  setActiveView: (view: string) => void;
}

const ViewBar: React.FC<ViewBarProps> = ({ activeView, setActiveView }) => {
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  const viewIcons = [
    {
      id: 'grid',
      IconComponent: LayoutGrid,
      label: 'Grid view',
      selectable: true,
    },
    { id: 'list', IconComponent: List, label: 'List view', selectable: true },
  ];

  return (
    <div className="bg-soft-cloud dark:bg-darker-gray p-3 rounded-lg shadow-lg flex space-x-4">
      {viewIcons.map(({ id, IconComponent, label, selectable }) => {
        const handleClick = () => {
          if (selectable) {
            setActiveView(id);
          }
        };

        return (
          IconComponent && (
            <div
              key={id}
              className={`group p-2 rounded-md cursor-pointer relative ${
                selectable && activeView === id
                  ? 'bg-midnight-coal dark:bg-cadet-gray'
                  : 'hover:bg-midnight-coal dark:hover:bg-cadet-gray'
              }`}
              onClick={handleClick}
              onMouseEnter={() => setHoveredIcon(id)}
              onMouseLeave={() => setHoveredIcon(null)}
            >
              <IconComponent
                className={`w-6 h-6 ${
                  selectable && activeView === id
                    ? 'text-anti-flash-white dark:text-darker-gray'
                    : 'text-midnight-coal dark:text-cadet-gray group-hover:text-anti-flash-white dark:group-hover:text-darker-gray'
                }`}
              />

              {hoveredIcon === id && (
                <div className="absolute left-1/2 transform -translate-x-1/2 -top-8 px-2 py-1 text-xs bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-md z-50 whitespace-nowrap">
                  {label}
                </div>
              )}
            </div>
          )
        );
      })}
    </div>
  );
};

export default ViewBar;
