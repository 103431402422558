import React from 'react';
import Graph from '../components/Graph';
import { viewsData, subscribersData } from '../mock/graphData';
import {
  CommentsFieldDesktop,
  CommentsFieldMobile,
} from '../components/CommentsField';
import { useMediaQuery } from 'react-responsive';
import { LayoutDashboard } from 'lucide-react';
import UploadCard from '../components/UploadCard';

const DashboardPage: React.FC = () => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <div className="p-6 bg-anti-flash-white dark:bg-dark-gray">
      {/* Page heading with icon and stats */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
        <div className="flex items-center">
          <h1 className="text-2xl sm:text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center mr-6">
            <LayoutDashboard className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
            My Overview
          </h1>
        </div>
        <div className="flex flex-wrap items-center gap-4">
          <Stat label="Videos" value="11" />
          <Stat label="Subscribers" value="412" />
          <Stat label="Views" value="6,192" />
          <Stat label="Likes" value="666" />
          <Stat label="Revenue" value="$ 1,337" />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <UploadCard />
        {/* Graphs Container */}
        <div className="lg:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Views Chart */}
          <div className="bg-soft-cloud dark:bg-darker-gray p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4 text-midnight-coal dark:text-soft-cloud">
              Views
            </h2>
            <div className="w-full h-[300px]">
              <Graph data={viewsData} color="#4C51BF" />
            </div>
          </div>

          {/* Subscribers Chart */}
          <div className="bg-soft-cloud dark:bg-darker-gray p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4 text-midnight-coal dark:text-soft-cloud">
              Subscribers
            </h2>
            <div className="w-full h-[300px]">
              <Graph data={subscribersData} color="#48BB78" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-col lg:flex-row justify-center items-start gap-6">
        {isDesktop ? (
          <>
            <CommentsFieldDesktop type="latest" />
            <CommentsFieldDesktop type="popular" />
          </>
        ) : (
          <>
            <CommentsFieldMobile type="latest" />
            <CommentsFieldMobile type="popular" />
          </>
        )}
      </div>
    </div>
  );
};

const Stat: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <div className="flex items-center gap-2">
    <span className="bg-[#E6DDD1] dark:bg-medium-gray px-2 py-1 rounded-md font-semibold text-base text-midnight-coal dark:text-soft-cloud">
      {value}
    </span>
    <span className="text-base text-midnight-coal dark:text-soft-cloud">
      {label}
    </span>
  </div>
);

export default DashboardPage;
