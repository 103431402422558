import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

interface GraphProps {
  data: { date: string; value: number }[];
  color: string;
}

const Graph: React.FC<GraphProps> = ({ data, color }) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-soft-cloud dark:bg-darker-gray p-2 border border-cadet-gray dark:border-medium-gray shadow-md">
          <p className="text-sm font-semibold text-midnight-coal dark:text-soft-cloud">
            {label}
          </p>
          <p className="text-sm text-midnight-coal dark:text-soft-cloud">
            value : <span style={{ color }}>{payload[0].value}</span>
          </p>
        </div>
      );
    }
    return null;
  };

  const maxValue = Math.max(...data.map((item) => item.value));
  const yAxisDomain = [0, maxValue * 1.5];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        data={data}
        margin={{ left: 0, right: 20, top: 20, bottom: 0 }}
      >
        <defs>
          <linearGradient
            id={`colorGradient${color}`}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          axisLine={{ stroke: '#E5E7EB' }}
          tickLine={false}
          tick={{ fontSize: 12 }}
          dy={10}
          className="text-cadet-gray dark:text-gray-500"
        />
        <YAxis
          axisLine={{ stroke: '#E5E7EB' }}
          tickLine={false}
          tick={{ fontSize: 12 }}
          dx={-10}
          domain={yAxisDomain}
          className="text-cadet-gray dark:text-gray-500"
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="value"
          stroke={color}
          strokeWidth={2}
          fill={`url(#colorGradient${color})`}
          fillOpacity={0.3}
          dot={false}
          activeDot={{ r: 6, fill: color, stroke: 'white', strokeWidth: 2 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Graph;
