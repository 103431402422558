import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import AuthPageLayout from '../components/AuthPageLayout';
import { toast } from 'react-toastify';
import PasswordInput from '../components/PasswordInput';

const RegisterPage: React.FC = () => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    register,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
  } = useAuth();
  const navigate = useNavigate();

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    try {
      await register();
      await new Promise((resolve) => setTimeout(resolve, 100));
      navigate('/dashboard');
    } catch (error) {
      toast.error(
        error instanceof Error
          ? error.message
          : 'Registration failed. Please try again.',
      );
    }
  };

  return (
    <AuthPageLayout title="Sign up">
      <form onSubmit={handleRegister} className="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-midnight-coal dark:text-soft-cloud text-sm mb-2"
          >
            Email address
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-md border border-cadet-gray dark:border-midnight-coal"
            placeholder="Enter your email address"
            required
          />
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-midnight-coal dark:text-soft-cloud text-sm mb-2"
          >
            Password
          </label>
          <PasswordInput
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            showPassword={showPassword}
            toggleShowPassword={() => setShowPassword(!showPassword)}
            required
            placeholder="Enter your password"
          />
        </div>
        <div>
          <label
            htmlFor="confirmPassword"
            className="block text-midnight-coal dark:text-soft-cloud text-sm mb-2"
          >
            Confirm Password
          </label>
          <PasswordInput
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            showPassword={showConfirmPassword}
            toggleShowPassword={() =>
              setShowConfirmPassword(!showConfirmPassword)
            }
            required
            placeholder="Confirm your password"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray p-3 rounded-md font-medium hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-200"
        >
          Register
        </button>
        <div className="text-center text-midnight-coal dark:text-soft-cloud">
          Already have an account?{' '}
          <Link to="/login" className="text-cadet-gray hover:underline">
            Login
          </Link>
        </div>
      </form>
    </AuthPageLayout>
  );
};

export default RegisterPage;
