import { Upload } from 'lucide-react';
import React, { useState, useEffect } from 'react';

interface UploadBoxProps {
  className?: string;
  setUploadVideo: (files: File) => void;
  currentFile?: File | null;
}

interface ThumbnailUploadBoxProps {
  className?: string;
  setThumbnail: (file: File) => void;
}

export const UploadBox: React.FC<UploadBoxProps> = ({
  className,
  setUploadVideo,
  currentFile,
}) => {
  const [videoPreview, setVideoPreview] = useState<string>('');

  useEffect(() => {
    if (currentFile) {
      const videoUrl = URL.createObjectURL(currentFile);
      setVideoPreview(videoUrl);
      return () => URL.revokeObjectURL(videoUrl);
    }
  }, [currentFile]);

  return (
    <div
      className={`bg-soft-cloud dark:bg-darker-gray p-6 rounded-lg border-2 border-dotted border-midnight-coal dark:border-cadet-gray max-w-xl h-80 ${className}`}
    >
      <div className="flex flex-col items-center justify-center h-full">
        {currentFile ? (
          <div className="w-full flex flex-col items-center">
            <div className="relative w-48 h-36 mb-4 bg-darker-gray rounded-lg overflow-hidden">
              <video
                src={videoPreview}
                className="absolute inset-0 w-full h-full object-cover rounded-lg"
                controls={false}
              />
            </div>
            <p className="text-midnight-coal dark:text-cadet-gray text-sm mb-3 truncate max-w-full">
              {currentFile.name}
            </p>
            <label
              htmlFor="file-upload"
              className="px-6 py-2 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-full text-midnight-coal dark:text-cadet-gray cursor-pointer hover:bg-midnight-coal hover:text-soft-cloud transition-colors"
            >
              Change file
            </label>
          </div>
        ) : (
          <>
            <div className="bg-desert-sand text-darker-gray rounded-full p-8 mb-4">
              <Upload className="w-12 h-12" />
            </div>
            <p className="text-midnight-coal dark:text-cadet-gray text-center text-lg mb-4">
              Drag & drop video file to upload
            </p>
            <label
              htmlFor="file-upload"
              className="px-6 py-2 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-full text-midnight-coal dark:text-cadet-gray cursor-pointer hover:bg-midnight-coal hover:text-soft-cloud transition-colors"
            >
              Select file
            </label>
          </>
        )}
        <input
          type="file"
          id="file-upload"
          className="hidden"
          accept="video/*"
          onChange={(e) => {
            const files = e.target.files;
            if (files && files[0]) {
              setUploadVideo(files[0]);
            }
          }}
        />
      </div>
    </div>
  );
};

export const ThumbnailUploadBox: React.FC<ThumbnailUploadBoxProps> = ({
  className,
  setThumbnail,
}) => {
  const [preview, setPreview] = useState<string>('');
  const [filename, setFilename] = useState<string>('');

  const handleThumbnailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setThumbnail(file);
      setFilename(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      className={`bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg border-2 border-dotted border-midnight-coal dark:border-cadet-gray h-48 w-40 flex-shrink-0 ${className}`}
    >
      <div className="flex flex-col items-center justify-center h-full space-y-2">
        {preview ? (
          <>
            <div className="relative w-24 h-16 bg-darker-gray rounded-lg overflow-hidden">
              <img
                src={preview}
                alt="Thumbnail preview"
                className="absolute inset-0 w-full h-full object-cover rounded-lg"
              />
            </div>
            <p className="text-midnight-coal dark:text-cadet-gray text-center text-xs truncate max-w-[8rem]">
              {filename}
            </p>
            <label
              htmlFor="thumbnail-upload"
              className="px-4 py-1 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-full text-midnight-coal dark:text-cadet-gray cursor-pointer hover:bg-midnight-coal hover:text-soft-cloud transition-colors text-sm text-center flex items-center justify-center"
            >
              Change thumbnail
            </label>
          </>
        ) : (
          <>
            <div className="bg-desert-sand text-darker-gray rounded-full p-4">
              <Upload className="w-8 h-8" />
            </div>
            <p className="text-midnight-coal dark:text-cadet-gray text-center text-sm">
              Add a thumbnail image
            </p>
            <label
              htmlFor="thumbnail-upload"
              className="px-4 py-1 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-full text-midnight-coal dark:text-cadet-gray cursor-pointer hover:bg-midnight-coal hover:text-soft-cloud transition-colors text-sm"
            >
              Select image
            </label>
          </>
        )}
        <input
          type="file"
          id="thumbnail-upload"
          className="hidden"
          accept="image/*"
          onChange={handleThumbnailChange}
        />
      </div>
    </div>
  );
};
