import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { MessageCircle } from 'lucide-react';
import { CommentsFieldDesktop } from '../components/CommentsField';
import { CommentsFieldMobile } from '../components/CommentsField';

const CommentsPage: React.FC = () => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <div className="relative h-full flex flex-col bg-anti-flash-white dark:bg-dark-gray p-4 sm:p-6">
      <div className="flex items-center justify-between mb-6 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
          <MessageCircle className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
          Comments
        </h1>
      </div>
      {isDesktop ? (
        <>
          <CommentsFieldDesktop type="latest" />
          <CommentsFieldDesktop type="popular" />
        </>
      ) : (
        <>
          <CommentsFieldMobile type="latest" />
          <CommentsFieldMobile type="popular" />
        </>
      )}
    </div>
  );
};

export default CommentsPage;
