const backendApiUrl = process.env.REACT_APP_API_URL;

interface ApiResponse {
  status: string;
  [key: string]: any;
}

export const changeEmail = async (email: string): Promise<ApiResponse> => {
  try {
    const response = await fetch(`${backendApiUrl}/user/change-email`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ email: email }),
    });
    const data: ApiResponse = await response.json();

    if (data.status !== 'OK') {
      throw new Error('Failed to change email');
    }
    return data;
  } catch (error) {
    console.error('Change email failed:', (error as Error).message);
    throw error;
  }
};

export const deleteUser = async (): Promise<ApiResponse> => {
  try {
    const response = await fetch(`${backendApiUrl}/user`, {
      method: 'DELETE',
      credentials: 'include',
    });
    const data: ApiResponse = await response.json();

    if (data.status !== 'OK') {
      throw new Error('Delete user failed');
    }
    return data;
  } catch (error) {
    console.error('Delete user failed:', (error as Error).message);
    throw error;
  }
};

export const getUserInfo = async (): Promise<ApiResponse> => {
  try {
    const response = await fetch(`${backendApiUrl}/user`);
    return await response.json();
  } catch (error) {
    console.error('User info error:', (error as Error).message);
    throw error;
  }
};
