import React from 'react';
import { useSidebar } from '../hooks/useSidebar';
import { useNavigate } from 'react-router-dom';
import { LogOut } from 'lucide-react';
import { signOut } from '../services/SuperTokensService';
import { useUser } from './useUserContext';

const MobileMenu: React.FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const { icons, getActiveStatus, isRouteValid } = useSidebar();
  const navigate = useNavigate();
  const { logout } = useUser();

  const handleMenuClick = (path: string) => {
    if (isRouteValid(path)) {
      navigate(path);
      closeMenu();
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      await logout();
      closeMenu();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex">
      <div className="bg-anti-flash-white dark:bg-dark-gray w-3/5 flex flex-col">
        <div className="flex-grow overflow-y-auto">
          <div className="p-6 space-y-6">
            {icons.map(({ id, path, IconComponent }) => {
              const isActive = getActiveStatus(path);
              const isValid = isRouteValid(path);

              return (
                <div
                  key={id}
                  className={`flex items-center space-x-4 p-4 rounded-lg text-lg font-bold ${
                    isActive
                      ? 'bg-midnight-coal text-anti-flash-white dark:bg-cadet-gray dark:text-darker-gray'
                      : isValid
                        ? 'hover:bg-midnight-coal hover:text-anti-flash-white dark:hover:bg-cadet-gray dark:hover:text-darker-gray cursor-pointer text-midnight-coal dark:text-cadet-gray'
                        : 'opacity-50 cursor-not-allowed text-midnight-coal dark:text-cadet-gray'
                  }`}
                  onClick={() => handleMenuClick(path)}
                >
                  <IconComponent
                    className={`w-8 h-8 ${isActive ? 'text-anti-flash-white dark:text-darker-gray' : 'text-midnight-coal dark:text-cadet-gray'}`}
                  />
                  <span
                    className={`text-lg font-bold ${isActive ? 'text-anti-flash-white dark:text-darker-gray' : 'text-midnight-coal dark:text-cadet-gray'}`}
                  >
                    {id}
                  </span>
                </div>
              );
            })}

            {/* Logout option */}
            <div
              className="flex items-center space-x-4 p-4 rounded-lg text-lg font-bold hover:bg-midnight-coal hover:text-anti-flash-white dark:hover:bg-cadet-gray dark:hover:text-darker-gray cursor-pointer text-midnight-coal dark:text-cadet-gray"
              onClick={handleLogout}
            >
              <LogOut className="w-8 h-8 text-midnight-coal dark:text-cadet-gray" />
              <span className="text-lg font-bold">Logout</span>
            </div>
          </div>
        </div>
      </div>
      {/* Overlay to handle clicks outside the menu */}
      <div className="flex-1 bg-black bg-opacity-50" onClick={closeMenu}></div>
    </div>
  );
};

export default MobileMenu;
