import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { submitNewPassword } from '../services/SuperTokensService';
import AuthPageLayout from '../components/AuthPageLayout';
import PasswordInput from '../components/PasswordInput';

const ResetPasswordPage: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      toast.error('Invalid reset link');
      navigate('/login');
    }
  }, [token, navigate]);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!token) {
      toast.error('Invalid reset link');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    setIsLoading(true);

    try {
      const response = await submitNewPassword(password);
      if (response.status === 'OK') {
        toast.success('Password reset successful');
        setTimeout(() => navigate('/login'), 2000);
      } else {
        throw new Error('Failed to reset password');
      }
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : 'Failed to reset password',
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return null;
  }

  return (
    <AuthPageLayout title="Reset Password">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="password"
            className="block text-midnight-coal dark:text-soft-cloud text-sm mb-2"
          >
            New Password
          </label>
          <PasswordInput
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            showPassword={showPassword}
            toggleShowPassword={() => setShowPassword(!showPassword)}
            required
            placeholder="Enter your new password"
            minLength={8}
          />
        </div>

        <div>
          <label
            htmlFor="confirmPassword"
            className="block text-midnight-coal dark:text-soft-cloud text-sm mb-2"
          >
            Confirm New Password
          </label>
          <PasswordInput
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            showPassword={showConfirmPassword}
            toggleShowPassword={() =>
              setShowConfirmPassword(!showConfirmPassword)
            }
            required
            placeholder="Confirm your new password"
            minLength={8}
          />
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray p-3 rounded-md font-medium hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-200 disabled:opacity-50"
        >
          {isLoading ? 'Resetting...' : 'Reset Password'}
        </button>

        <div className="text-center text-midnight-coal dark:text-soft-cloud">
          Remember your password?{' '}
          <Link to="/auth/login" className="text-cadet-gray hover:underline">
            Log in
          </Link>
        </div>
      </form>
    </AuthPageLayout>
  );
};

export default ResetPasswordPage;
