import { addDays, format } from 'date-fns';

const generateMockData = (startValue: number, volatility: number) => {
  const today = new Date();
  return Array.from({ length: 30 }, (_, i) => {
    const date = addDays(today, -29 + i);
    const value = Math.max(
      0,
      Math.round(startValue + (Math.random() - 0.5) * volatility * 2),
    );
    return { date: format(date, 'MMM dd'), value };
  });
};

export const viewsData = generateMockData(1000, 200);
export const subscribersData = generateMockData(100, 20);
