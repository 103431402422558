import React from 'react';
import { Outlet } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import Sidebar from './Sidebar';

interface LayoutProps {
  isMobileMenuOpen: boolean;
  setMobileMenuOpen: (isOpen: boolean) => void;
}

const Layout: React.FC<LayoutProps> = ({
  isMobileMenuOpen,
  setMobileMenuOpen,
}) => {
  return (
    <div className="flex flex-1 h-full">
      {/* Desktop Sidebar */}
      <div className="hidden sm:flex flex-col items-center w-24 bg-anti-flash-white dark:bg-dark-gray">
        <Sidebar />
      </div>
      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <MobileMenu closeMenu={() => setMobileMenuOpen(false)} />
      )}
      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
