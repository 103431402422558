import React, { useState, useEffect } from 'react';
import { fetchVideos } from '../assets/videoData';
import { Video } from '../types/videoData';
import { Eye, MessageCircle } from 'lucide-react';
import VideoDetailsModal from './VideoDetailsModal';
import { SiVimeo, SiYoutube } from 'react-icons/si';
import { SiX } from 'react-icons/si';

interface MobileVideoGridProps {
  setHasVideos: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileVideoGrid: React.FC<MobileVideoGridProps> = ({ setHasVideos }) => {
  const [videoData, setVideoData] = useState<Video[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

  useEffect(() => {
    fetchVideos().then((videos) => {
      setVideoData(videos);
      setHasVideos(videos.length > 0);
    });
  }, [setHasVideos]);

  const openModal = (video: Video) => {
    setSelectedVideo(video);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="grid grid-cols-2 gap-4 p-4">
      {videoData.map((video, index) => (
        <div
          key={index}
          className="bg-soft-cloud dark:bg-darker-gray rounded-lg shadow-lg overflow-hidden cursor-pointer"
          onClick={() => openModal(video)}
        >
          <div className="aspect-w-16 aspect-h-9">
            <img
              src={video.thumbnail}
              alt={video.title}
              className="object-cover w-full h-full"
            />
          </div>
          <div className="p-2">
            <h2 className="text-sm font-semibold text-midnight-coal dark:text-soft-cloud mb-1 truncate">
              {video.title}
            </h2>
            <div className="text-midnight-coal dark:text-soft-cloud font-bold mb-2 flex items-center">
              {video.platforms.map((platform: string) => (
                <a
                  href={
                    platform === 'YouTube'
                      ? video.youtubeUrl
                      : platform === 'X'
                        ? video.xUrl
                        : video.vimeoUrl
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  key={platform}
                  className="mr-2 inline-flex items-center"
                >
                  {platform === 'YouTube' && (
                    <SiYoutube className="w-4 h-4 mr-1" />
                  )}
                  {platform === 'X' && <SiX className="w-4 h-4 mr-1" />}
                  {platform === 'Vimeo' && <SiVimeo className="w-4 h-4 mr-1" />}
                </a>
              ))}
            </div>
            <div className="flex justify-start text-cadet-gray text-xs">
              <div className="flex items-center mr-2">
                <Eye className="w-3 h-3 mr-1" />
                {video.views}
              </div>
              <div className="flex items-center">
                <MessageCircle className="w-3 h-3 mr-1" />
                {video.commentCount}
              </div>
            </div>
          </div>
        </div>
      ))}
      {selectedVideo && (
        <VideoDetailsModal video={selectedVideo} onClose={closeModal} />
      )}
    </div>
  );
};

export default MobileVideoGrid;
