import React from 'react';
import { Edit3, Trash } from 'lucide-react';
import { useTags } from '../hooks/useTags'; // Import the custom hook

const TagsInput: React.FC = () => {
  const {
    tags,
    inputValue,
    menuTagIndex,
    hoveredIcon,
    setHoveredIcon,
    setInputValue,
    handleKeyDown,
    handleTagClick,
    removeTag,
    editTag,
    handlePaste, // New paste handler
  } = useTags();

  return (
    <div className="flex flex-col space-y-2">
      <label className="block text-midnight-coal dark:text-cadet-gray font-medium">
        Tags
      </label>

      {/* Tags Display */}
      <div className="flex flex-wrap gap-2">
        {tags.map((tag, index) => (
          <div key={index} className="relative">
            <div
              onClick={() => handleTagClick(index)}
              className="flex items-center bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray px-3 py-1 rounded-full cursor-pointer max-w-full"
            >
              <span className="break-words whitespace-normal">{tag}</span>
            </div>

            {/* Icon-Only Dropdown Menu */}
            {menuTagIndex === index && (
              <div className="absolute bg-soft-cloud dark:bg-darker-gray p-2 rounded-md shadow-lg -top-14 left-1/2 transform -translate-x-1/2 z-10 flex space-x-4">
                {/* Edit Icon with Tooltip */}
                <div
                  className="relative group"
                  onMouseEnter={() => setHoveredIcon('edit')}
                  onMouseLeave={() => setHoveredIcon(null)}
                >
                  <button
                    className="p-2 rounded-md hover:bg-midnight-coal dark:hover:bg-cadet-gray"
                    onClick={() => editTag(index)}
                  >
                    <Edit3 className="w-6 h-6 text-midnight-coal dark:text-cadet-gray group-hover:text-anti-flash-white dark:group-hover:text-darker-gray" />
                  </button>
                  {hoveredIcon === 'edit' && (
                    <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 p-1 text-xs bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-md">
                      Edit
                    </div>
                  )}
                </div>

                {/* Delete Icon with Tooltip */}
                <div
                  className="relative group"
                  onMouseEnter={() => setHoveredIcon('delete')}
                  onMouseLeave={() => setHoveredIcon(null)}
                >
                  <button
                    className="p-2 rounded-md hover:bg-midnight-coal dark:hover:bg-cadet-gray"
                    onClick={() => removeTag(tag)}
                  >
                    <Trash className="w-6 h-6 text-midnight-coal dark:text-cadet-gray group-hover:text-anti-flash-white dark:group-hover:text-darker-gray" />
                  </button>
                  {hoveredIcon === 'delete' && (
                    <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 p-1 text-xs bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-md">
                      Delete
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Input Field */}
      <input
        type="text"
        placeholder="Add tags"
        value={inputValue}
        onKeyDown={handleKeyDown}
        onChange={(e) => setInputValue(e.target.value)}
        onPaste={(e) => {
          handlePaste(e.clipboardData.getData('text')); // Capture paste event
          e.preventDefault(); // Prevent default paste behavior
        }}
        className="w-full p-2 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-md text-darker-gray dark:text-soft-cloud"
      />
      <small className="text-midnight-coal dark:text-cadet-gray">
        Separate tags with a comma
      </small>
    </div>
  );
};

export default TagsInput;
