import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import AuthPageLayout from '../components/AuthPageLayout';
import { toast } from 'react-toastify';
import PasswordInput from '../components/PasswordInput';

const LoginPage: React.FC = () => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    showPassword,
    setShowPassword,
    login,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('deleted') === 'true') {
      toast.success('Your account has been successfully deleted');
    }
  }, [location]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    await login();
    navigate('/dashboard');
  };

  return (
    <AuthPageLayout title="Log in">
      <form onSubmit={handleLogin} className="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-midnight-coal dark:text-soft-cloud text-sm mb-2"
          >
            Email address
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-md border border-cadet-gray dark:border-midnight-coal"
            placeholder="Enter your email address"
            required
          />
        </div>
        <div>
          <div className="flex justify-between items-center mb-2">
            <label
              htmlFor="password"
              className="block text-midnight-coal dark:text-soft-cloud text-sm"
            >
              Password
            </label>
            <Link
              to="/forgot-password"
              className="text-sm text-cadet-gray hover:text-midnight-coal dark:hover:text-soft-cloud transition-colors duration-300"
            >
              Forgot Password?
            </Link>
          </div>
          <PasswordInput
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            showPassword={showPassword}
            toggleShowPassword={() => setShowPassword(!showPassword)}
            required
            placeholder="Enter your password"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray p-3 rounded-md font-medium hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-200"
        >
          Log In
        </button>
        <div className="text-center text-midnight-coal dark:text-soft-cloud">
          Don't have an account?{' '}
          <Link to="/register" className="text-cadet-gray hover:underline">
            Register
          </Link>
        </div>
      </form>
    </AuthPageLayout>
  );
};

export default LoginPage;
