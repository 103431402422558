import React from 'react';
import { Menu } from 'lucide-react'; // Hamburger icon

const MobileHeader: React.FC<{ toggleMobileMenu: () => void }> = ({
  toggleMobileMenu,
}) => {
  return (
    <div className="flex items-center justify-between p-4 bg-anti-flash-white dark:bg-dark-gray">
      <div className="flex items-center">
        <button onClick={toggleMobileMenu} aria-label="Toggle mobile menu">
          <Menu className="w-6 h-6 text-midnight-coal dark:text-soft-cloud" />
        </button>
        <img src="/logo.svg" alt="Logo" className="w-10 h-10 ml-2" />
        <h1 className="text-2xl font-bold text-midnight-coal">Sub</h1>
        <h1 className="text-2xl font-bold text-cadet-gray">Synced</h1>
      </div>
    </div>
  );
};

export default MobileHeader;
