import React from 'react';
import { SquarePen, Copy, Trash2, Eye, EyeOff } from 'lucide-react';

const MultiselectBar: React.FC = () => {
  const icons = [
    { id: 'edit', IconComponent: SquarePen },
    { id: 'copy', IconComponent: Copy },
    { id: 'delete', IconComponent: Trash2 },
    { id: 'view', IconComponent: Eye },
    { id: 'hide', IconComponent: EyeOff },
  ];

  return (
    <div className="bg-soft-cloud dark:bg-darker-gray p-3 rounded-lg shadow-lg flex justify-center">
      <div className="flex space-x-4 text-cadet-gray relative">
        {icons.map(({ id, IconComponent }) => (
          <div
            key={id}
            className="group p-2 rounded-md cursor-pointer bg-soft-cloud dark:bg-darker-gray hover:bg-midnight-coal dark:hover:bg-cadet-gray"
          >
            <IconComponent className="w-6 h-6 group-hover:text-anti-flash-white dark:group-hover:text-darker-gray" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiselectBar;
