import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  PlatformsSectionDesktop,
  PlatformsSectionMobile,
} from '../components/PlatformsSection';
import { usePlatforms } from '../hooks/usePlatforms';
import { TicketCheck } from 'lucide-react';

const PlatformsPage: React.FC = () => {
  const { platformData, handleConnect, handleConnectMore, disconnectChannel } =
    usePlatforms();
  const [modalOpen, setModalOpen] = useState(false);
  const [channelToDisconnect, setChannelToDisconnect] = useState<{
    platformIndex: number;
    channelIndex: number;
  } | null>(null);
  const location = useLocation();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const platform = queryParams.get('platform');
    const success = queryParams.get('success');
    if (platform && success) {
      if (success === 'true') {
        toast.success(`Successfully authorized ${platform}!`);
      } else {
        toast.error(`Failed to authorize ${platform}. Please try again.`);
      }
    }
  }, [location]);

  const openDisconnectModal = (platformIndex: number, channelIndex: number) => {
    setChannelToDisconnect({ platformIndex, channelIndex });
    setModalOpen(true);
  };

  const handleDisconnect = () => {
    if (channelToDisconnect) {
      disconnectChannel(
        channelToDisconnect.platformIndex,
        channelToDisconnect.channelIndex,
      );
    }
    setModalOpen(false);
    setChannelToDisconnect(null);
  };

  const handleCreateChannel = (platformIndex: number) => {
    const platformName = platformData[platformIndex].name;
    let url = '';
    switch (platformName) {
      case 'YouTube':
        url = 'https://www.youtube.com/create_channel';
        break;
      case 'X':
        url = 'https://twitter.com/i/flow/signup';
        break;
      case 'Vimeo':
        url = 'https://vimeo.com/join';
        break;
      default:
        console.error(`Unknown platform: ${platformName}`);
        return;
    }
    window.open(url, '_blank');
  };

  return (
    <div className="relative h-full flex flex-col bg-anti-flash-white dark:bg-dark-gray p-4 sm:p-6">
      <ToastContainer />
      <div className="flex items-center justify-between mb-6 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
          <TicketCheck className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
          Platforms
        </h1>
      </div>
      {isDesktop ? (
        <PlatformsSectionDesktop platformData={platformData} />
      ) : (
        <PlatformsSectionMobile platformData={platformData} />
      )}
    </div>
  );
};

export default PlatformsPage;
