import React, { useEffect, useState } from 'react';
import FoldOutHeader from './FoldOutHeader';
import { UploadBox, ThumbnailUploadBox } from './UploadBox';
import VideoFormContent from './VideoFormContent';
import FoldOutFooter from './FoldOutFooter';
import { Channel } from '../types/channels';
import { UploadData } from '../types/uploadData';
import { useFooterActions } from '../hooks/useFooterActions';
import { AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const backendApiUrl = process.env.REACT_APP_API_URL;

interface FoldOutMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
  initialFiles?: File[];
}

const FoldOutMenu: React.FC<FoldOutMenuProps> = ({
  isOpen,
  toggleMenu,
  initialFiles = [],
}) => {
  const fetchChannels = async (): Promise<{ channels: Channel[] } | null> => {
    try {
      const response = await fetch(`${backendApiUrl}/channel`, {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Failed to fetch channels');
      }
      const text = await response.text();
      let data: { channels: Channel[] };
      try {
        data = JSON.parse(text);
      } catch (parseError) {
        console.error('Error parsing JSON:', parseError);
        console.error('Raw response:', text);
        throw new Error('Invalid JSON response');
      }
      return data;
    } catch (error) {
      console.error('Error fetching channels:', error);
      return null;
    }
  };

  const [channels, setChannels] = useState<Channel[]>([]);

  useEffect(() => {
    const data = fetchChannels();
    data.then((result) => {
      if (result) {
        setChannels(
          result.channels.map((channel: Channel) => ({
            ...channel,
            isActive: false,
          })),
        );
      }
    });
  }, []);

  const [description, setDescription] = React.useState<string>('');
  const [title, setTitle] = React.useState<string>('');
  const [video, setVideo] = React.useState<File | null>(null);
  const [thumbnail, setThumnail] = React.useState<File | null>(null);
  const [upload, setUpload] = React.useState<UploadData>();

  useEffect(() => {
    const upload: UploadData = {
      userId: '',
      title,
      description,
      thumbnail: thumbnail ? thumbnail : null,
      channels,
      status: '',
      error: '',
      mediaFile: video,
    };

    setUpload(upload);
  }, [title, description, thumbnail, video, channels]);

  useFooterActions(toggleMenu);

  useEffect(() => {
    if (isOpen && initialFiles.length > 0) {
      setVideo(initialFiles[0]);
    }
  }, [isOpen, initialFiles]);

  const navigate = useNavigate();

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleMenu}
        />
      )}
      <div
        className={`fixed top-0 right-0 transform transition-transform duration-300 ease-in-out
          w-full sm:w-4/5 lg:w-3/5 xl:w-2/5 2xl:w-1/2 min-w-[50%] max-h-[100vh]
          bg-anti-flash-white dark:bg-dark-gray rounded-l-lg sm:rounded-none z-50 flex flex-col
          ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <FoldOutHeader toggleMenu={toggleMenu} />
        <div className="flex-1 px-4 overflow-y-auto">
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 mb-4">
            <UploadBox
              setUploadVideo={setVideo}
              currentFile={video}
              className="flex-grow"
            />
            <ThumbnailUploadBox
              setThumbnail={setThumnail}
              className="w-80 lg:w-40"
            />
          </div>
          <VideoFormContent
            setTitle={setTitle}
            setDescription={setDescription}
            title={title}
            description={description}
            channels={channels}
            setChannels={setChannels}
          />
          <FoldOutFooter
            toggleMenu={toggleMenu}
            upload={upload as UploadData}
            hasChannels={channels.length > 0}
          />
        </div>

        {channels.length === 0 && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[60]">
            <div className="bg-soft-cloud dark:bg-darker-gray p-6 rounded-lg shadow-xl max-w-md w-[90%] mx-4">
              <div className="flex items-center mb-4">
                <AlertCircle className="w-6 h-6 text-red-500 mr-2" />
                <h2 className="text-xl font-semibold text-midnight-coal dark:text-soft-cloud">
                  No Channels Connected
                </h2>
              </div>
              <p className="text-midnight-coal dark:text-cadet-gray mb-6">
                You need to connect at least one channel before you can upload
                videos. Would you like to connect your channels now?
              </p>
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    toggleMenu();
                    navigate('/platforms');
                  }}
                  className="px-4 py-2 bg-midnight-coal text-soft-cloud dark:bg-cadet-gray dark:text-darker-gray rounded-md hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-200"
                >
                  Connect Channels
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FoldOutMenu;
