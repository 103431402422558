import { useState } from 'react';
import {
  signIn,
  signUp,
  sendVerificationEmail,
} from '../services/SuperTokensService';
import { toast } from 'react-toastify';

export const useAuth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const login = async () => {
    try {
      const response = await signIn(email, password);
      if (response.status === 'OK') {
        window.location.href = '/dashboard';
      } else {
        toast.error('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error(
        error instanceof Error
          ? error.message
          : 'Login failed. Please try again.',
      );
    }
  };

  const register = async () => {
    try {
      const response = await signUp(email, password);
      if (response.status === 'OK') {
        try {
          await sendVerificationEmail();
          window.location.href = '/dashboard';
        } catch (error) {
          console.error('Verification email failed:', error);
          toast.error('Account created but verification email failed to send.');
        }
      } else {
        toast.error('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Registration error:', error);
      if (error instanceof Error) {
        throw error;
      } else {
        throw new Error('Registration failed. Please try again.');
      }
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    login,
    register,
  };
};
