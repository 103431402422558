import { useState } from 'react';
import { uploadVideoToServer } from '../services/VideoService';
import Session from 'supertokens-web-js/recipe/session';
import { UploadData } from '../types/uploadData';

export const useFooterActions = (toggleMenu: () => void) => {
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = (upload: UploadData) => {
    setIsUploading(true);
    console.log('Uploading...');

    Session.getUserId()
      .then((userId) => {
        upload.userId = userId;
        uploadVideoToServer(upload);
        setTimeout(() => {
          setIsUploading(false);
          toggleMenu();
        }, 2000);
      })
      .catch((error) => {
        console.error('Error getting user ID:', error);
        setIsUploading(false);
      });
  };

  const handleCancel = () => {
    toggleMenu();
  };

  return { handleUpload, handleCancel, isUploading };
};
