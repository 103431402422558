import React, { useState } from 'react';
import { SiX, SiVimeo, SiYoutube } from 'react-icons/si';
import { Edit, ChevronDown } from 'lucide-react';
import { Channel } from '../types/channels';

interface ChannelPickerProps {
  channels: Channel[];
  setChannels: React.Dispatch<React.SetStateAction<Channel[]>>;
}

const ChannelPicker: React.FC<ChannelPickerProps> = ({
  channels,
  setChannels,
}) => {
  const [selectedGroup, setSelectedGroup] = useState('All channels');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const predefinedChannels = channels.slice(0, 3);

  const getIcon = (platform: string) => {
    switch (platform) {
      case 'YouTube':
        return (
          <SiYoutube className="w-6 h-6 text-midnight-coal dark:text-cadet-gray" />
        );
      case 'Vimeo':
        return (
          <SiVimeo className="w-6 h-6 text-midnight-coal dark:text-cadet-gray" />
        );
      case 'X':
        return (
          <SiX className="w-6 h-6 text-midnight-coal dark:text-cadet-gray" />
        );
      default:
        return null;
    }
  };

  const toggleChannelStatus = (id: number) => {
    setChannels((prevChannels) =>
      prevChannels.map((channel) =>
        channel.id === id
          ? { ...channel, isActive: !channel.isActive }
          : channel,
      ),
    );
  };

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  const handleGroupSelect = (group: string) => {
    if (group !== 'Edit channel groups') {
      setSelectedGroup(group);
    }
    setIsDropdownOpen(false);
  };

  const handleEditChannelGroups = () => {
    console.log('Edit channel groups clicked');
    // You can replace this with actual logic to edit channel groups
  };

  return (
    <div className="w-full max-w-md">
      <label className="block text-midnight-coal dark:text-cadet-gray font-medium mb-2">
        Select Channel Group
      </label>

      {/* Custom Dropdown */}
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="w-full bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-md p-2 flex items-center justify-between"
        >
          <span className="text-midnight-coal dark:text-cadet-gray">
            {selectedGroup}
          </span>
          <ChevronDown className="w-5 h-5 text-midnight-coal dark:text-cadet-gray" />
        </button>

        {isDropdownOpen && (
          <ul className="absolute w-full mt-2 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-md z-10">
            <li
              onClick={() => handleGroupSelect('All channels')}
              className="cursor-pointer p-2 hover:bg-midnight-coal dark:hover:bg-cadet-gray text-midnight-coal dark:text-cadet-gray hover:text-soft-cloud dark:hover:text-darker-gray"
            >
              All channels
            </li>
            <li
              onClick={() => handleGroupSelect('Predefined channel group')}
              className="cursor-pointer p-2 hover:bg-midnight-coal dark:hover:bg-cadet-gray text-midnight-coal dark:text-cadet-gray hover:text-soft-cloud dark:hover:text-darker-gray"
            >
              Predefined channel group
            </li>
            <li
              onClick={handleEditChannelGroups} // Acts as a button without changing selection
              className="cursor-pointer p-2 flex items-center justify-between hover:bg-midnight-coal dark:hover:bg-cadet-gray text-midnight-coal dark:text-cadet-gray hover:text-soft-cloud dark:hover:text-darker-gray"
            >
              Edit channel groups
              <Edit className="w-5 h-5" />
            </li>
          </ul>
        )}
      </div>

      <div className="mt-4 space-y-4">
        {(selectedGroup === 'Predefined channel group'
          ? predefinedChannels
          : channels
        ).map((channel) => (
          <div key={channel.id} className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              {getIcon(channel.platform)}
              <div>
                <p className="text-midnight-coal dark:text-cadet-gray">
                  {channel.name}
                </p>{' '}
                {/* Updated text color */}
                <p className="text-darker-gray dark:text-cadet-gray text-sm">
                  {channel.subscribers} subscribers • {channel.videos} videos
                </p>
              </div>
            </div>
            <div
              onClick={() => toggleChannelStatus(channel.id)}
              className={`w-12 h-6 flex items-center bg-${
                channel.isActive
                  ? `midnight-coal dark:cadet-gray`
                  : `cadet-gray dark:dark-gray`
              } rounded-full p-1 cursor-pointer transition-colors duration-300`}
            >
              <div
                className={`bg-${channel.isActive ? 'anti-flash-white' : 'soft-cloud'} w-4 h-4 rounded-full shadow-md transform ${
                  channel.isActive ? 'translate-x-6' : 'translate-x-0'
                } transition-transform duration-300`}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChannelPicker;
