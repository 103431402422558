import React, { useState } from 'react';
import { Upload } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const UploadCard: React.FC = () => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleFiles = (files: File[]) => {
    navigate('/videos', {
      state: {
        openUploadMenu: true,
        selectedFiles: files,
      },
    });
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith('video/'),
    );

    if (files.length > 0) {
      handleFiles(files);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files).filter((file) =>
        file.type.startsWith('video/'),
      );

      if (files.length > 0) {
        handleFiles(files);
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={`bg-soft-cloud dark:bg-darker-gray p-6 rounded-lg shadow-md ${
        isDragging ? 'border-2 border-dashed border-cadet-gray' : ''
      }`}
    >
      <div className="flex flex-col items-center justify-center h-full">
        <Upload className="w-16 h-16 text-cadet-gray mb-4" />
        <p className="text-midnight-coal dark:text-soft-cloud text-center mb-4">
          Drag & drop video file(s) to upload.
        </p>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          accept="video/*"
          multiple
          className="hidden"
        />
        <button
          onClick={handleButtonClick}
          className="bg-midnight-coal text-soft-cloud dark:bg-cadet-gray dark:text-darker-gray px-4 py-2 rounded-md"
        >
          Select files...
        </button>
      </div>
    </div>
  );
};

export default UploadCard;
