import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardPage from './pages/DashboardPage';
import ColorsPage from './pages/ColorsPage';
import VideosPage from './pages/VideosPage';
import PlatformsPage from './pages/PlatformsPage';
import SettingsPage from './pages/SettingsPage';
import Layout from './components/Layout';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import { useDarkMode } from './hooks/useDarkMode';
import CommentsPage from './pages/CommentsPage';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Header from './components/Header';
import MobileHeader from './components/MobileHeader';
import { useMediaQuery } from 'react-responsive';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';

export const validRoutes = [
  '/dashboard',
  '/videos',
  '/platforms',
  '/settings',
  '/login',
  '/register',
  '/colors',
  '/comments',
  '/forgot-password',
  '/reset-password',
];

const App: React.FC = () => {
  const { isDarkMode } = useDarkMode();
  const isMobile = useMediaQuery({ maxWidth: 639 }); // Assuming 640px is the breakpoint for sm
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

  React.useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-anti-flash-white dark:bg-dark-gray">
        <ToastContainer />
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route
              path="/auth/reset-password"
              element={<ResetPasswordPage />}
            />
          </Route>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route element={<PrivateRoute />}>
            <Route
              element={
                <>
                  {isMobile ? (
                    <MobileHeader toggleMobileMenu={toggleMobileMenu} />
                  ) : (
                    <Header />
                  )}
                  <Layout
                    isMobileMenuOpen={isMobileMenuOpen}
                    setMobileMenuOpen={setMobileMenuOpen}
                  />
                </>
              }
            >
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/colors" element={<ColorsPage />} />
              <Route path="/videos" element={<VideosPage />} />
              <Route path="/platforms" element={<PlatformsPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/comments" element={<CommentsPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
