import React from 'react';
import { LayoutGrid, List } from 'lucide-react';

interface MobileViewBarProps {
  activeView: string;
  setActiveView: (view: string) => void;
}

const MobileViewBar: React.FC<MobileViewBarProps> = ({
  activeView,
  setActiveView,
}) => {
  return (
    <div className="flex h-10 bg-soft-cloud dark:bg-darker-gray rounded-md overflow-hidden">
      <button
        onClick={() => setActiveView('grid')}
        className={`flex items-center justify-center px-3 ${
          activeView === 'grid'
            ? 'bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray'
            : 'text-midnight-coal dark:text-soft-cloud hover:bg-gray-200 dark:hover:bg-gray-700'
        }`}
      >
        <LayoutGrid className="w-5 h-5" />
      </button>
      <button
        onClick={() => setActiveView('list')}
        className={`flex items-center justify-center px-3 ${
          activeView === 'list'
            ? 'bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray'
            : 'text-midnight-coal dark:text-soft-cloud hover:bg-gray-200 dark:hover:bg-gray-700'
        }`}
      >
        <List className="w-5 h-5" />
      </button>
    </div>
  );
};

export default MobileViewBar;
