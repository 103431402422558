import React, { useState, useEffect } from 'react';
import { fetchVideos } from '../assets/videoData';
import { Video } from '../types/videoData';
import { Eye, MessageCircle } from 'lucide-react';
import { SiVimeo, SiX, SiYoutube } from 'react-icons/si';

interface VideoGridProps {
  setHasVideos: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoGrid: React.FC<VideoGridProps> = ({ setHasVideos }) => {
  const [videoData, setVideoData] = useState<Video[]>([]);

  useEffect(() => {
    fetchVideos().then((videos) => {
      setVideoData(videos);
      setHasVideos(videos.length > 0);
    });
  }, [setHasVideos]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {videoData.map((video, index) => (
        <div
          key={index}
          className="bg-soft-cloud dark:bg-darker-gray rounded-lg p-4 shadow-lg"
        >
          <div className="aspect-w-16 aspect-h-9 mb-4">
            <img
              src={video.thumbnail}
              alt={video.title}
              className="object-cover rounded"
            />
          </div>
          <h2 className="text-lg font-semibold text-midnight-coal dark:text-soft-cloud mb-2">
            {video.title}
          </h2>
          <p className="text-sm text-midnight-coal dark:text-soft-cloud mb-4">
            {video.description}
          </p>
          <div className="text-midnight-coal dark:text-soft-cloud font-bold mb-2 flex items-center">
            {video.platforms.map((platform: string) => (
              <a
                href={
                  platform === 'YouTube'
                    ? video.youtubeUrl
                    : platform === 'X'
                      ? video.xUrl
                      : video.vimeoUrl
                }
                target="_blank"
                rel="noopener noreferrer"
                key={platform}
                className="mr-2 inline-flex items-center"
              >
                {platform === 'YouTube' && (
                  <SiYoutube className="w-4 h-4 mr-1" />
                )}
                {platform === 'X' && <SiX className="w-4 h-4 mr-1" />}
                {platform === 'Vimeo' && <SiVimeo className="w-4 h-4 mr-1" />}
              </a>
            ))}
          </div>
          <div className="flex items-center text-cadet-gray text-sm">
            <span className="flex items-center mr-4">
              <Eye className="w-4 h-4 mr-1" />
              {video.views}
            </span>
            <span className="flex items-center">
              <MessageCircle className="w-4 h-4 mr-1" />
              {video.commentCount}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoGrid;
