import React from 'react';
import { useVisibility } from '../hooks/useVisibility';

const Visibility: React.FC = () => {
  const { selectedVisibility, visibilityOptions, handleVisibilityChange } =
    useVisibility();

  return (
    <div className="flex flex-col space-y-2">
      <label className="block text-midnight-coal dark:text-cadet-gray font-medium">
        Visibility
      </label>
      <div className="flex space-x-6">
        {visibilityOptions.map(({ id, label, Icon }) => {
          const isSelected = selectedVisibility === id;

          return (
            <div
              key={id}
              className={`flex flex-col items-center cursor-pointer relative`}
              onClick={() => handleVisibilityChange(id)}
            >
              <div
                className={`p-2 rounded-md group relative ${
                  isSelected
                    ? 'bg-midnight-coal dark:bg-cadet-gray'
                    : 'hover:bg-midnight-coal dark:hover:bg-cadet-gray'
                }`}
              >
                <Icon
                  className={`w-6 h-6 ${
                    isSelected
                      ? 'text-anti-flash-white dark:text-darker-gray'
                      : 'text-cadet-gray group-hover:text-anti-flash-white dark:group-hover:text-darker-gray'
                  }`}
                />
                {isSelected && (
                  <div className="absolute bottom-[-6px] left-[20%] right-[20%] h-1 bg-midnight-coal dark:bg-cadet-gray rounded-bl-full rounded-br-full" />
                )}
              </div>
              <span className="mt-2 text-sm text-midnight-coal dark:text-cadet-gray">
                {label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Visibility;
