import { faker } from '@faker-js/faker';

export const commentsData = [
  {
    id: 1,
    platform: 'YouTube',
    username: faker.internet.userName(),
    profilePic: faker.image.avatar(),
    content: 'This is an amazing video! Learned a lot.',
    likes: 120,
    dislikes: 5,
    thumbnail: faker.image.url(),
    replies: [
      {
        id: 101,
        username: faker.internet.userName(),
        profilePic: faker.image.avatar(),
        content: 'I agree! Very informative.',
        likes: 15,
        dislikes: 0,
      },
      {
        id: 102,
        username: faker.internet.userName(),
        profilePic: faker.image.avatar(),
        content: 'Thanks for sharing!',
        likes: 10,
        dislikes: 1,
      },
    ],
  },
  {
    id: 2,
    platform: 'X',
    username: faker.internet.userName(),
    profilePic: faker.image.avatar(),
    content: 'Interesting perspective, but I have some doubts.',
    hearts: 5,
    retweets: 3,
    thumbnail: faker.image.url(),
    replies: [
      {
        id: 201,
        username: faker.internet.userName(),
        profilePic: faker.image.avatar(),
        content: 'What doubts do you have?',
        hearts: 0,
        retweets: 1,
      },
    ],
  },
  {
    id: 3,
    platform: 'Vimeo',
    username: faker.internet.userName(),
    profilePic: faker.image.avatar(),
    content: 'Beautifully shot and edited!',
    likes: 200,
    dislikes: 2,
    thumbnail: faker.image.url(),
    replies: [],
  },
  // Add more comments here
  {
    id: 4,
    platform: 'YouTube',
    username: faker.internet.userName(),
    profilePic: faker.image.avatar(),
    content: 'Loved the editing style!',
    likes: 80,
    dislikes: 3,
    thumbnail: faker.image.url(),
    replies: [],
  },
  {
    id: 5,
    platform: 'X',
    username: faker.internet.userName(),
    profilePic: faker.image.avatar(),
    content: 'Great insights!',
    hearts: 12,
    retweets: 4,
    thumbnail: faker.image.url(),
    replies: [],
  },
  {
    id: 6,
    platform: 'Vimeo',
    username: faker.internet.userName(),
    profilePic: faker.image.avatar(),
    content: 'Fantastic visuals!',
    likes: 150,
    dislikes: 5,
    thumbnail: faker.image.url(),
    replies: [],
  },
  // Add more as needed
];
