import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from './useUserContext';

const PrivateRoute: React.FC = () => {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return user.isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
