import React, { useRef, useEffect } from 'react';
import {
  Eye,
  ThumbsUp,
  ThumbsDown,
  MessageCircle,
  Calendar,
  Coins,
  Edit,
  BarChart2,
} from 'lucide-react';

interface VideoDetailsModalProps {
  video: {
    thumbnail: string;
    title: string;
    uploadDate: Date;
    views: string;
    likes: string;
    dislikes: string;
    commentCount: string;
    revenue: string;
  };
  onClose: () => void;
}

const VideoDetailsModal: React.FC<VideoDetailsModalProps> = ({
  video,
  onClose,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-soft-cloud dark:bg-darker-gray rounded-lg p-4 w-full max-w-md mx-4"
      >
        <img
          src={video.thumbnail}
          alt={video.title}
          className="w-full h-auto object-cover rounded mb-4"
        />
        <h2 className="text-xl font-bold text-midnight-coal dark:text-soft-cloud mb-2">
          {video.title}
        </h2>
        <div className="grid grid-cols-2 gap-2 text-sm text-cadet-gray mb-4">
          <div className="flex items-center">
            <Calendar className="w-4 h-4 mr-1" />
            {new Date(video.uploadDate).toISOString().split('T')[0]}
          </div>
          <div className="flex items-center">
            <Eye className="w-4 h-4 mr-1" />
            {video.views}
          </div>
          <div className="flex items-center">
            <ThumbsUp className="w-4 h-4 mr-1" />
            {video.likes}
          </div>
          <div className="flex items-center">
            <ThumbsDown className="w-4 h-4 mr-1" />
            {video.dislikes}
          </div>
          <div className="flex items-center">
            <MessageCircle className="w-4 h-4 mr-1" />
            {video.commentCount}
          </div>
          <div className="flex items-center">
            <Coins className="w-4 h-4 mr-1" />
            {video.revenue}
          </div>
        </div>
        <div className="flex justify-between space-x-2">
          <button className="flex items-center justify-center px-4 py-2 bg-soft-cloud dark:bg-cadet-gray text-midnight-coal dark:text-darker-gray rounded-lg hover:bg-cadet-gray hover:text-soft-cloud dark:hover:bg-soft-cloud dark:hover:text-midnight-coal transition-colors duration-200">
            <Edit className="w-4 h-4 mr-2" />
            Details
          </button>
          <button
            className="flex items-center justify-center px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-500 dark:text-gray-400 rounded-lg cursor-not-allowed"
            disabled
          >
            <BarChart2 className="w-4 h-4 mr-2" />
            Analytics
          </button>
          <button
            className="flex items-center justify-center px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-500 dark:text-gray-400 rounded-lg cursor-not-allowed"
            disabled
          >
            <MessageCircle className="w-4 h-4 mr-2" />
            Comments
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoDetailsModal;
