import React, { useState, useEffect } from 'react';
import { FileVideo, Upload, ArrowUp } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import SortBar from '../components/SortBar';
import ViewBar from '../components/ViewBar';
import MobileSortBar from '../components/MobileSortBar';
import MobileViewBar from '../components/MobileViewBar';
import UploadButton from '../components/UploadButton';
import VideoList from '../components/VideoList';
import VideoGrid from '../components/VideoGrid';
import MobileVideoList from '../components/MobileVideoList';
import MobileVideoGrid from '../components/MobileVideoGrid';
import MultiselectBar from '../components/MultiselectBar';
import FoldOutMenu from '../components/FoldOutMenu';

const VideosPage: React.FC = () => {
  const location = useLocation();
  const [isFoldOutMenuOpen, setIsFoldOutMenuOpen] = useState(false);
  const [hasVideos, setHasVideos] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleUpload = () => {
    setIsFoldOutMenuOpen(true);
  };

  const [activeView, setActiveView] = useState(() => {
    const savedView = localStorage.getItem('activeView');
    return savedView || 'grid';
  });

  useEffect(() => {
    if (location.state?.openUploadMenu) {
      setIsFoldOutMenuOpen(true);
      if (location.state.selectedFiles) {
        setSelectedFiles(location.state.selectedFiles);
      }
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  useEffect(() => {
    localStorage.setItem('activeView', activeView);
  }, [activeView]);

  const toggleFoldOutMenu = () => {
    setIsFoldOutMenuOpen(!isFoldOutMenuOpen);
  };

  return (
    <div className="relative h-full flex flex-col bg-anti-flash-white dark:bg-dark-gray">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 sm:mb-8 p-4 sm:p-6">
        <h1 className="text-2xl sm:text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center mb-4 sm:mb-0">
          <FileVideo className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
          Videos
        </h1>

        {/* Desktop controls - unchanged */}
        <div className="hidden sm:flex space-x-4 items-center">
          <SortBar />
          <div className="flex space-x-4 items-center">
            <ViewBar activeView={activeView} setActiveView={setActiveView} />
            <UploadButton handleUpload={handleUpload} hasVideos={hasVideos} />
          </div>
        </div>

        {/* Mobile controls */}
        <div className="sm:hidden w-full flex items-start space-x-2">
          <MobileSortBar />
          <MobileViewBar
            activeView={activeView}
            setActiveView={setActiveView}
          />
          <div className="flex flex-col items-end ml-auto relative">
            <button
              onClick={toggleFoldOutMenu}
              className="p-2 h-10 bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-md shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none"
            >
              <Upload className="w-5 h-5" />
            </button>
            {!hasVideos && (
              <>
                <div className="absolute top-full left-1/2 -translate-x-1/2 mt-1">
                  <ArrowUp className="w-6 h-6 text-midnight-coal dark:text-cadet-gray" />
                </div>
                <div className="absolute top-full right-0 mt-7 px-2 py-1 text-xs text-midnight-coal dark:text-cadet-gray bg-soft-cloud dark:bg-darker-gray rounded-lg shadow-sm whitespace-nowrap z-10">
                  Upload your first video!
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={`flex-1 ${isFoldOutMenuOpen ? 'opacity-50' : 'opacity-100'}`}
      >
        {hasVideos ? (
          <>
            <div className="hidden sm:block">
              {activeView === 'list' ? (
                <VideoList setHasVideos={setHasVideos} />
              ) : (
                <VideoGrid setHasVideos={setHasVideos} />
              )}
            </div>
            <div className="block sm:hidden">
              {activeView === 'list' ? (
                <MobileVideoList setHasVideos={setHasVideos} />
              ) : (
                <MobileVideoGrid setHasVideos={setHasVideos} />
              )}
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center w-full h-full pt-16 sm:pt-24">
            <h1 className="text-3xl sm:text-4xl font-light text-midnight-coal dark:text-cadet-gray text-center mb-8">
              No Videos Yet
            </h1>
          </div>
        )}
      </div>
      <footer
        className={`hidden sm:flex mt-8 justify-center ${isFoldOutMenuOpen ? 'opacity-50' : 'opacity-100'}`}
      >
        <MultiselectBar />
      </footer>
      <FoldOutMenu
        isOpen={isFoldOutMenuOpen}
        toggleMenu={toggleFoldOutMenu}
        initialFiles={selectedFiles}
      />
    </div>
  );
};

export default VideosPage;
