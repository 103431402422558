import React from 'react';
import { Calendar } from 'lucide-react';
import { useSchedulePicker } from '../hooks/useSchedulePicker';

const SchedulePicker: React.FC = () => {
  const {
    isOpen,
    scheduleTime,
    stagedDate,
    stagedTime,
    shouldOpenUpward,
    minTime,
    popupRef,
    parentRef,
    getDateOptions,
    handleDateChange,
    handleTimeChange,
    handleConfirm,
    setIsOpen,
  } = useSchedulePicker();

  return (
    <div className="relative w-full max-w-md" ref={parentRef}>
      <label className="block text-midnight-coal dark:text-cadet-gray font-medium mb-2">
        Schedule
      </label>

      <div
        className="flex items-center justify-between border border-midnight-coal dark:border-cadet-gray rounded-md p-4 cursor-pointer bg-soft-cloud dark:bg-darker-gray"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-midnight-coal dark:text-soft-cloud">
          {scheduleTime}
        </span>
        <Calendar className="text-midnight-coal dark:text-cadet-gray" />
      </div>

      {isOpen && (
        <div
          ref={popupRef}
          className={`absolute z-10 w-full bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-md shadow-lg p-4 max-h-64 overflow-auto ${
            shouldOpenUpward ? 'bottom-full mb-2' : 'top-full mt-2'
          }`}
        >
          <div className="flex flex-col space-y-4">
            <div>
              <label className="block text-midnight-coal dark:text-cadet-gray mb-1">
                Select Date
              </label>
              <select
                value={stagedDate}
                className="w-full bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-md p-2 text-midnight-coal dark:text-soft-cloud"
                onChange={handleDateChange}
              >
                <option value="Immediately">Immediately</option>
                {getDateOptions().map((date) => (
                  <option key={date.value} value={date.value}>
                    {date.label}
                  </option>
                ))}
              </select>
            </div>

            {stagedDate !== 'Immediately' && (
              <div>
                <label className="block text-midnight-coal dark:text-cadet-gray mb-1">
                  Select Time
                </label>
                <div className="flex items-center">
                  <input
                    type="time"
                    min={minTime}
                    value={stagedTime || ''}
                    className="w-full bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-md p-2 text-midnight-coal dark:text-soft-cloud"
                    onChange={handleTimeChange}
                  />
                </div>
              </div>
            )}

            <button
              className="bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-midnight-coal py-2 rounded-md w-full"
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulePicker;
