import React, { useState } from 'react';
import AuthenticationMenu from './AuthenticationMenu';
import { Platform } from '../types/platforms';
import { SiVimeo } from 'react-icons/si';
import { SiYoutube } from 'react-icons/si';
import { SiX } from 'react-icons/si';

interface PlatformsSectionProps {
  platformData: Array<Platform>;
}

const PlatformsSectionDesktop: React.FC<PlatformsSectionProps> = ({
  platformData,
}) => {
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);

  const handleButtonClick = (platformName: string) => {
    setSelectedPlatform(platformName);
  };

  const handleCloseMenu = () => {
    setSelectedPlatform(null);
  };

  const getIcon = (platform: string) => {
    switch (platform) {
      case 'YouTube':
        return <SiYoutube className="text-red-600 text-4xl" />;
      case 'X':
        return <SiX className="dark:text-white text-black text-4xl" />;
      case 'Vimeo':
        return <SiVimeo className="text-blue-600 text-4xl" />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col sm:flex-row bg-anti-flash-white dark:bg-dark-gray rounded-lg">
      <div className="flex-1 space-y-4">
        {platformData.map((platform) => (
          <div
            key={platform.name}
            className={`flex items-center justify-between p-4 rounded-lg border-2 ${
              platform.connected
                ? 'bg-soft-cloud dark:bg-dark-bg-gray'
                : 'bg-anti-flash-white dark:bg-dark-gray'
            } border-gray-400 dark:border-gray-600`}
          >
            <div className="flex items-center space-x-4">
              {getIcon(platform.name)}
              <span className="text-midnight-coal dark:text-soft-cloud text-2xl">
                {platform.name}
              </span>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-cadet-gray">
                {platform.connected
                  ? `Connected (${platform.channels.map(channel => channel.name).join(', ')})`
                  : 'Not connected'}
              </span>
              <button
                className="px-4 py-2 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud rounded-lg"
                onClick={() => handleButtonClick(platform.name)}
              >
                {platform.connected ? 'Disconnect' : 'Connect'}
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="flex-1 p-4 bg-anti-flash-white dark:bg-dark-bg-gray rounded-lg ml-0 sm:ml-4 border-2 border-dotted border-gray-400 dark:border-gray-600 h-80">
        <h2 className="text-cadet-gray mb-2">Zero state instructions.</h2>
        <p className="text-cadet-gray">Eg. Select platform to connect with.</p>
        <p className="text-cadet-gray">Preferably with nice illustration.</p>
      </div>
      {selectedPlatform && (
        <AuthenticationMenu
          platformName={selectedPlatform}
          onClose={handleCloseMenu}
        />
      )}
    </div>
  );
};

const PlatformsSectionMobile: React.FC<PlatformsSectionProps> = ({
  platformData,
}) => {
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);

  const handleButtonClick = (platformName: string) => {
    setSelectedPlatform(platformName);
  };

  const handleCloseMenu = () => {
    setSelectedPlatform(null);
  };

  const getIcon = (platform: string) => {
    switch (platform) {
      case 'YouTube':
        return <SiYoutube className="text-red-600 text-4xl" />;
      case 'X':
        return <SiX className="dark:text-white text-black text-4xl" />;
      case 'Vimeo':
        return <SiVimeo className="text-blue-600 text-4xl" />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col bg-anti-flash-white dark:bg-dark-gray rounded-lg space-y-4">
      {platformData.map((platform) => (
        <div
          key={platform.name}
          className={`flex items-center justify-between p-4 rounded-lg border-2 ${
            platform.connected
              ? 'bg-soft-cloud dark:bg-dark-bg-gray'
              : 'bg-anti-flash-white dark:bg-dark-gray'
          } border-gray-400 dark:border-gray-600`}
        >
          <div className="flex items-center space-x-4">
            {getIcon(platform.name)}
            <span className="text-midnight-coal dark:text-soft-cloud text-2xl">
              {platform.name}
            </span>
          </div>
          <div className="flex flex-col items-end">
            <button
              className="px-4 py-2 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud rounded-lg mb-2"
              onClick={() => handleButtonClick(platform.name)}
            >
              {platform.connected ? 'Disconnect' : 'Connect'}
            </button>
            <span className="text-cadet-gray text-right">
              {platform.connected
                ? `Connected (@myaccount +${platform.channels.length})`
                : 'Not connected'}
            </span>
          </div>
        </div>
      ))}
      {selectedPlatform && (
        <AuthenticationMenu
          platformName={selectedPlatform}
          onClose={handleCloseMenu}
        />
      )}
    </div>
  );
};

export { PlatformsSectionDesktop, PlatformsSectionMobile };
