import { useState } from 'react';
import {
  CalendarDays,
  ArrowDownAZ,
  Eye,
  ThumbsUp,
  ThumbsDown,
  MessageCircleMore,
  Coins,
  Tally1,
  Search,
} from 'lucide-react';

export type SortIcon = {
  id: string;
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  nonSortable?: boolean;
};

export const useSortBar = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    'Date uploaded',
  );
  const [sortDescending, setSortDescending] = useState(true);

  const icons: SortIcon[] = [
    { id: 'Date uploaded', IconComponent: CalendarDays },
    { id: 'Title alphabetically', IconComponent: ArrowDownAZ },
    { id: 'Views', IconComponent: Eye },
    { id: 'Likes', IconComponent: ThumbsUp },
    { id: 'Dislikes', IconComponent: ThumbsDown },
    { id: 'Comments', IconComponent: MessageCircleMore },
    { id: 'Revenue', IconComponent: Coins },
    { id: 'divider', IconComponent: Tally1, nonSortable: true },
    { id: 'Search', IconComponent: Search, nonSortable: true },
  ];

  const handleIconClick = (id: string) => {
    // Ensure non-sortable icons like 'search' and 'divider' are not clickable as sort options
    if (id === 'Search' || id === 'divider') return;

    if (selectedCategory === id) {
      // Toggle descending/ascending for the selected category
      if (sortDescending) {
        setSortDescending(false);
      } else {
        // If already ascending, reset sort to default (date uploaded)
        setSelectedCategory('Date uploaded');
        setSortDescending(true);
      }
    } else {
      // Select a new category and default to descending order
      setSelectedCategory(id);
      setSortDescending(true);
    }
  };

  const getSortStatus = (id: string) => {
    // If no category is selected, default to 'Date uploaded'
    const currentCategory = selectedCategory || 'Date uploaded';
    const isDescending = currentCategory === id ? sortDescending : true;

    return {
      isSelected: currentCategory === id,
      sortDescending: isDescending,
    };
  };

  return { icons, handleIconClick, getSortStatus };
};
