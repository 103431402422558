import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  confirmStyle?: 'primary' | 'danger';
  cancelText?: string;
  showCancel?: boolean;
  message?: string;
  confirmText?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmStyle = 'primary',
  cancelText,
  showCancel = false,
  disabled = false,
  confirmText = 'Confirm',
  children,
}) => {
  if (!isOpen) return null;

  const getConfirmButtonStyle = () => {
    if (confirmStyle === 'danger') {
      return 'bg-red-600 hover:bg-red-700 text-white';
    }
    return 'bg-desert-sand dark:bg-cadet-gray text-midnight-coal dark:text-soft-cloud';
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white dark:bg-darker-gray p-6 rounded-lg w-96">
        <h2 className="text-xl font-bold mb-4 text-midnight-coal dark:text-soft-cloud">
          {title}
        </h2>
        <div className="mb-6 text-midnight-coal dark:text-soft-cloud">
          {message || children}
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-midnight-coal dark:text-soft-cloud"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={disabled}
            className="px-4 py-2 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud rounded-md disabled:opacity-50"
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
