import React from 'react';

const ColorsPage: React.FC = () => {
  return (
    <div className="h-full  bg-anti-flash-white dark:bg-dark-gray">
      <h1 className="text-midnight-coal dark:text-soft-cloud text-4xl font-bold mb-8">
        Color Palette
      </h1>
      <div className="space-y-4">
        <div className="p-4 bg-anti-flash-white dark:bg-dark-gray text-midnight-coal dark:text-soft-cloud">
          Anti-flash White / Dark Gray (#E8E9EB / #202125)
        </div>
        <div className="p-4 bg-midnight-coal text-soft-cloud">
          Midnight Coal (#424B54)
        </div>
        <div className="p-4 bg-desert-sand text-darker-gray dark:text-soft-cloud">
          Desert Sand (#D8CAB8)
        </div>
        <div className="p-4 bg-soft-cloud text-dark-gray dark:text-midnight-coal">
          Soft Cloud (#FAF9F9)
        </div>
        <div className="p-4 bg-cadet-gray text-darker-gray dark:text-soft-cloud">
          Cadet Gray (#88A2AA)
        </div>
        <div className="p-4 bg-dark-gray text-soft-cloud">
          Dark Gray (#202125)
        </div>
        <div className="p-4 bg-darker-gray text-soft-cloud">
          Darker Gray (#121212)
        </div>
        <div className="p-4 bg-medium-gray text-soft-cloud">
          Medium Gray (#747F8A)
        </div>
      </div>
    </div>
  );
};

export default ColorsPage;
