import React, { useState, useEffect } from 'react';
import { FaYoutube, FaVimeo } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { ChevronsRight, Plus } from 'lucide-react';
import { usePlatforms } from '../hooks/usePlatforms';
interface AuthenticationMenuProps {
  platformName: string;
  onClose: () => void;
}

const backendApiUrl = process.env.REACT_APP_API_URL;

const platformIcons: { [key: string]: JSX.Element } = {
  YouTube: <FaYoutube className="text-red-600 text-2xl" />,
  X: <FaSquareXTwitter className="dark:text-white text-black text-2xl" />,
  Vimeo: <FaVimeo className="text-blue-600 text-2xl" />,
};


const AuthenticationMenu: React.FC<AuthenticationMenuProps> = ({
  platformName,
  onClose,
}) => {

  const { createNewChannel } = usePlatforms();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  return (
    <div className="fixed inset-0 flex">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={handleClose}
      ></div>
      <div
        className={`relative ml-auto h-full w-full sm:w-96 bg-anti-flash-white dark:bg-dark-gray p-6 shadow-lg transform transition-transform duration-300 ease-in-out ${
          isVisible ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                {platformIcons[platformName]}
                <h2 className="text-midnight-coal dark:text-soft-cloud text-xl font-semibold ml-2">
                  Connect {platformName}
                </h2>
              </div>
              <ChevronsRight
                className="text-midnight-coal dark:text-soft-cloud text-lg cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="bg-gray-200 dark:bg-gray-800 p-4 rounded-lg mb-4">
              <p className="text-cadet-gray">
                Area for authentication widget from platform
              </p>
            </div>
            <div className="bg-gray-200 dark:bg-gray-800 p-4 rounded-lg">
              <p className="text-cadet-gray">Channels will appear here</p>
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            <button
              className="flex items-center justify-center h-16 w-48 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud rounded-lg hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-200"
              onClick={() => {
                createNewChannel(platformName);
              }}
            >
              <Plus className="w-8 h-8 mr-3" />
              <span className="text-2xl font-medium">Connect</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationMenu;
