import { Channel } from '../types/channels';


const backendApiUrl = process.env.REACT_APP_API_URL;
export const fetchChannels = async (): Promise<{ channels: Channel[] } | null> => {
  try {
    const response = await fetch(`${backendApiUrl}/channel`, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch channels');
    }
    const text = await response.text();
    let data: { channels: Channel[] };
    try {
      data = JSON.parse(text);
    } catch (parseError) {
      console.error('Error parsing JSON:', parseError);
      console.error('Raw response:', text);
      throw new Error('Invalid JSON response');
    }
    return data;
  } catch (error) {
    console.error('Error fetching channels:', error);
    return null;
  }
};