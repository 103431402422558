import SuperTokens from 'supertokens-web-js';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import EmailVerification from 'supertokens-web-js/recipe/emailverification';
import Session from 'supertokens-web-js/recipe/session';

interface FormField {
  id: string;
  value?: string;
  error?: string;
}

interface Response {
  status: string;
  formFields?: FormField[];
  reason?: string;
}

interface ForgotPasswordResponse {
  status: string;
  message?: string;
}

function checkForFormFieldError(response: any) {
  if (response.status === 'FIELD_ERROR') {
    response.formFields.forEach((field: any) => {
      if (
        field.id === 'email' &&
        field.error === 'This email already exists. Please sign in instead.'
      ) {
        throw new Error(
          'This email is already registered. Please sign in instead.',
        );
      }
      if (field.error) {
        throw new Error(field.error);
      }
    });
  }
}

function checkForError(
  response: Response,
  error: string,
  errorMessage: string,
): void {
  if (response.status === error) {
    throw new Error(errorMessage);
  }
}

function checkForReasonError(response: Response, error: string): void {
  checkForError(response, error, response.reason || '');
}

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

export const init = async (): Promise<void> => {
  SuperTokens.init({
    appInfo: {
      apiDomain: apiUrl,
      apiBasePath: '/auth',
      appName: 'SubSynced',
    },
    recipeList: [
      EmailVerification.init(),
      Session.init(),
      EmailPassword.init(),
    ],
  });
};

export const signUp = async (
  email: string,
  password: string,
): Promise<Response> => {
  try {
    const signupResponse = await EmailPassword.signUp({
      formFields: [
        { id: 'email', value: email },
        { id: 'password', value: password },
      ],
    });

    checkForFormFieldError(signupResponse);
    checkForReasonError(signupResponse, 'SIGN_UP_NOT_ALLOWED');

    if (signupResponse.status === 'OK') {
      const accountResponse = await fetch(`${apiUrl}/user/account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          userId: signupResponse.user.id,
          email: signupResponse.user.emails[0],
        }),
      });

      if (!accountResponse.ok) {
        throw new Error('Failed to create account in database');
      }
    }

    return signupResponse;
  } catch (error) {
    console.error(
      'Registration failed:',
      error instanceof Error ? error.message : error,
    );
    throw error;
  }
};

export const signIn = async (
  email: string,
  password: string,
): Promise<Response> => {
  try {
    const response = await EmailPassword.signIn({
      formFields: [
        {
          id: 'email',
          value: email,
        },
        {
          id: 'password',
          value: password,
        },
      ],
    });

    checkForFormFieldError(response);
    checkForReasonError(response, 'SIGN_IN_NOT_ALLOWED');
    checkForError(
      response,
      'WRONG_CREDENTIALS_ERROR',
      'Email password combination is incorrect.',
    );
    return response;
  } catch (error) {
    console.error(
      'Login request failed:',
      error instanceof Error ? error.message : error,
    );
    throw error;
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await Session.signOut();
  } catch (error) {
    console.error(
      'Logout request failed:',
      error instanceof Error ? error.message : error,
    );
    throw error;
  }
};

export const sendVerificationEmail = async (): Promise<Response> => {
  try {
    const response = await EmailVerification.sendVerificationEmail();
    checkForError(
      response,
      'EMAIL_ALREADY_VERIFIED_ERROR',
      'Email already verified.',
    );
    return response;
  } catch (error) {
    console.error(
      'Failed to resend verification email:',
      error instanceof Error ? error.message : error,
    );
    throw error;
  }
};

export const verifyEmail = async (): Promise<Response> => {
  try {
    const response = await EmailVerification.verifyEmail();
    checkForError(
      response,
      'EMAIL_VERIFICATION_INVALID_TOKEN_ERROR',
      'Invalid token.',
    );
    return response;
  } catch (error) {
    console.error(
      'Failed to verify email:',
      error instanceof Error ? error.message : error,
    );
    throw error;
  }
};

export const sendPasswordResetEmail = async (
  email: string,
): Promise<Response> => {
  try {
    const response = await EmailPassword.sendPasswordResetEmail({
      formFields: [
        {
          id: 'email',
          value: email,
        },
      ],
    });
    checkForFormFieldError(response);
    return response;
  } catch (error) {
    console.error(
      'Send password reset email failed:',
      error instanceof Error ? error.message : error,
    );
    throw error;
  }
};

export const submitNewPassword = async (
  password: string,
): Promise<Response> => {
  try {
    const response = await EmailPassword.submitNewPassword({
      formFields: [
        {
          id: 'password',
          value: password,
        },
      ],
    });

    checkForFormFieldError(response);
    checkForError(
      response,
      'RESET_PASSWORD_INVALID_TOKEN_ERROR',
      'Password reset failed. Invalid token.',
    );
    return response;
  } catch (error) {
    console.error(
      'Submitting new password failed:',
      error instanceof Error ? error.message : error,
    );
    throw error;
  }
};

export const sessionExists = async (): Promise<boolean> => {
  return await Session.doesSessionExist();
};

export const updateEmail = async (
  newEmail: string,
  password: string,
): Promise<Response> => {
  const response = await fetch(`${apiUrl}/user/change-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      email: newEmail,
      password: password,
    }),
    credentials: 'include',
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to update email');
  }

  return response.json();
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<Response> => {
  const response = await fetch(`${apiUrl}/user/password`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ currentPassword: oldPassword, newPassword }),
    credentials: 'include',
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to update password');
  }

  return response.json();
};

export const forgotPassword = async (
  email: string,
): Promise<ForgotPasswordResponse> => {
  const response = await fetch(`${apiUrl}/user/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ email }),
    credentials: 'include',
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to send reset email');
  }

  return data;
};
