import React, { useState } from 'react';
import { useSortBar } from '../hooks/useSortBar';

const SortBar: React.FC = () => {
  const { icons, handleIconClick, getSortStatus } = useSortBar();
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  return (
    <div className="bg-soft-cloud dark:bg-darker-gray p-3 rounded-lg shadow-lg flex flex-col space-y-4">
      <div className="flex space-x-4 text-cadet-gray relative items-center">
        {icons.map(({ id, IconComponent, nonSortable }) => {
          const { isSelected, sortDescending } = getSortStatus(id);

          if (id === 'divider') {
            return (
              <div
                key={id}
                className="w-0.5 h-8 bg-cadet-gray mx-4 rounded-full"
              ></div>
            );
          }

          return (
            <div
              key={id}
              className={`group p-2 rounded-md cursor-pointer relative flex flex-col items-center justify-center ${
                isSelected && !nonSortable
                  ? 'bg-midnight-coal dark:bg-cadet-gray'
                  : 'hover:bg-midnight-coal dark:hover:bg-cadet-gray'
              }`}
              onClick={() => handleIconClick(id)}
              onMouseEnter={() => setHoveredIcon(id)}
              onMouseLeave={() => setHoveredIcon(null)}
            >
              {isSelected && !nonSortable && (
                <div
                  className={`absolute ${
                    sortDescending ? 'bottom-[-6px]' : 'top-[-6px]'
                  } w-6 h-1 bg-midnight-coal dark:bg-cadet-gray z-10 ${
                    sortDescending
                      ? 'rounded-bl-full rounded-br-full'
                      : 'rounded-tl-full rounded-tr-full'
                  }`}
                ></div>
              )}
              <IconComponent
                className={`w-6 h-6 relative z-20 ${
                  isSelected
                    ? 'text-anti-flash-white dark:text-darker-gray'
                    : 'group-hover:text-anti-flash-white dark:group-hover:text-darker-gray text-medium-gray dark:text-cadet-gray'
                }`}
              />

              {/* Tooltip for search and other icons */}
              {hoveredIcon === id && (
                <div className="absolute left-1/2 transform -translate-x-1/2 -top-12 px-2 py-1 text-xs bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-md z-50 whitespace-nowrap">
                  {id === 'Search'
                    ? 'Search'
                    : id.charAt(0).toUpperCase() + id.slice(1)}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SortBar;
