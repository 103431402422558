import React, { useState } from 'react';
import {
  Settings,
  Mail,
  Lock,
  Sliders,
  User,
  AlertTriangle,
} from 'lucide-react';
import { useDarkMode } from '../hooks/useDarkMode';
import { useAccount } from '../hooks/useAccount';
import Modal from '../components/Modal';
import { deleteUser } from '../services/UserService';
import { signOut } from '../services/SuperTokensService';
import { toast } from 'react-toastify';
import PasswordInput from '../components/PasswordInput';

const SettingsPage: React.FC = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const {
    user,
    isEmailModalOpen,
    setEmailModalOpen,
    isPasswordModalOpen,
    setPasswordModalOpen,
    handleEmailChange,
    handlePasswordChange,
    isLoading,
  } = useAccount();

  const [newEmail, setNewEmail] = useState('');
  const [confirmNewEmail, setConfirmNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!newEmail) {
      toast.error('Please enter a new email address');
      return;
    }
    if (!password) {
      toast.error('Please enter your password');
      return;
    }
    handleEmailChange(newEmail, password);
  };

  const onPasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      toast.error('New passwords do not match');
      return;
    }
    handlePasswordChange(currentPassword, newPassword);
  };

  const handleDeleteAccount = async () => {
    try {
      setIsDeleting(true);
      await deleteUser();
      await signOut();
      localStorage.removeItem('supertokens-auth-state');
      window.location.href = '/login?deleted=true';
    } catch (error) {
      console.error('Failed to delete account:', error);
      toast.error('Failed to delete account. Please try again.');
    } finally {
      setIsDeleting(false);
      setDeleteModalOpen(false);
    }
  };

  return (
    <div className="relative h-full flex flex-col bg-anti-flash-white dark:bg-dark-gray p-6">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
          <Settings className="w-8 h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
          Settings
        </h1>
      </div>

      <div className="w-full max-w-2xl space-y-8">
        {/* General Settings */}
        <div>
          <h2 className="text-xl font-medium text-midnight-coal dark:text-cadet-gray flex items-center mb-4">
            <Sliders className="w-6 h-6 mr-2" />
            General
          </h2>
          <div className="bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg shadow-lg">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-lg font-medium text-midnight-coal dark:text-cadet-gray">
                  Dark Mode
                </h3>
                <p className="text-sm text-cadet-gray">
                  Toggle dark mode on or off
                </p>
              </div>

              {/* Toggle Switch */}
              <div
                onClick={toggleDarkMode}
                className={`w-12 h-6 flex items-center bg-${
                  isDarkMode
                    ? 'midnight-coal dark:bg-cadet-gray'
                    : 'cadet-gray dark:bg-dark-gray'
                } rounded-full p-1 cursor-pointer transition-colors duration-300`}
              >
                <div
                  className={`bg-${isDarkMode ? 'anti-flash-white' : 'soft-cloud'} w-4 h-4 rounded-full shadow-md transform ${
                    isDarkMode ? 'translate-x-6' : 'translate-x-0'
                  } transition-transform duration-300`}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {/* Account Settings */}
        <div>
          <h2 className="text-xl font-medium text-midnight-coal dark:text-cadet-gray flex items-center mb-4">
            <User className="w-6 h-6 mr-2" />
            Account
          </h2>

          {/* Email Settings */}
          <div className="bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg shadow-lg mb-4">
            <h3 className="text-lg font-medium text-midnight-coal dark:text-cadet-gray flex items-center mb-4">
              <Mail className="w-5 h-5 mr-2" />
              Change Email
            </h3>
            <p className="text-cadet-gray mb-4">
              Current email:{' '}
              <span className="font-medium">
                {user?.email || 'Not available'}{' '}
                {!user?.email && (
                  <span className="text-red-500">
                    (User data not loaded properly)
                  </span>
                )}
              </span>
            </p>
            <form onSubmit={onEmailSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  New Email
                </label>
                <input
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  required
                  className="w-full p-3 bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-md border border-cadet-gray dark:border-midnight-coal"
                  placeholder="Enter your new email"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  Confirm New Email
                </label>
                <input
                  type="email"
                  value={confirmNewEmail}
                  onChange={(e) => setConfirmNewEmail(e.target.value)}
                  required
                  className="w-full p-3 bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-md border border-cadet-gray dark:border-midnight-coal"
                  placeholder="Confirm your new email"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  Password
                </label>
                <PasswordInput
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  showPassword={showPassword}
                  toggleShowPassword={() => setShowPassword(!showPassword)}
                  required
                />
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full p-3 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-lg font-medium hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-300"
              >
                {isLoading ? 'Updating...' : 'Change Email'}
              </button>
            </form>
          </div>

          {/* Password Settings */}
          <div className="bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-medium text-midnight-coal dark:text-cadet-gray flex items-center mb-4">
              <Lock className="w-5 h-5 mr-2" />
              Change Password
            </h3>
            <form onSubmit={onPasswordSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  Current Password
                </label>
                <PasswordInput
                  id="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  showPassword={showCurrentPassword}
                  toggleShowPassword={() =>
                    setShowCurrentPassword(!showCurrentPassword)
                  }
                  required
                  placeholder="Enter your current password"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  New Password
                </label>
                <PasswordInput
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  showPassword={showNewPassword}
                  toggleShowPassword={() =>
                    setShowNewPassword(!showNewPassword)
                  }
                  required
                  placeholder="Enter your new password"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  Confirm New Password
                </label>
                <PasswordInput
                  id="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  showPassword={showConfirmNewPassword}
                  toggleShowPassword={() =>
                    setShowConfirmNewPassword(!showConfirmNewPassword)
                  }
                  required
                  placeholder="Confirm your new password"
                />
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full p-3 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-lg font-medium hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-300"
              >
                {isLoading ? 'Updating...' : 'Change Password'}
              </button>
            </form>
          </div>
        </div>

        {/* Delete Account Section */}
        <div className="bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg shadow-lg border-t-2 border-red-500">
          <h3 className="text-lg font-medium text-red-600 dark:text-red-400 flex items-center mb-4">
            <AlertTriangle className="w-5 h-5 mr-2" />
            Danger Zone
          </h3>
          <p className="text-cadet-gray mb-4">
            Once you delete your account, there is no going back. Please be
            certain.
          </p>
          <button
            onClick={() => setDeleteModalOpen(true)}
            className="w-full p-3 bg-red-600 text-white rounded-lg font-medium hover:bg-red-700 transition-colors duration-300"
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete Account'}
          </button>
        </div>
      </div>

      {/* Modals */}
      <Modal
        isOpen={isEmailModalOpen}
        onClose={() => {
          setEmailModalOpen(false);
          setPassword('');
        }}
        onConfirm={() => handleEmailChange(newEmail, password)}
        title="Confirm Email Change"
        confirmText={isLoading ? 'Updating...' : 'Confirm'}
        disabled={isLoading}
      >
        <div className="space-y-4">
          <p>Are you sure you want to change your email to {newEmail}?</p>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-cadet-gray mb-1"
            >
              Please enter your password to confirm
            </label>
            <PasswordInput
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              showPassword={showPassword}
              toggleShowPassword={() => setShowPassword(!showPassword)}
              required
              placeholder="Current password"
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isPasswordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
        onConfirm={() => handlePasswordChange(currentPassword, newPassword)}
        title="Confirm Password Change"
        confirmText={isLoading ? 'Updating...' : 'Confirm'}
        disabled={isLoading}
      >
        <p>Are you sure you want to change your password?</p>
      </Modal>
    </div>
  );
};

export default SettingsPage;
