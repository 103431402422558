import React, { useState } from 'react';
import {
  CalendarClock,
  Sparkles,
  ThumbsUp,
  ThumbsDown,
  Heart,
  ChevronDown,
  ChevronUp,
  Repeat2,
} from 'lucide-react';
import { SiYoutube, SiVimeo, SiX } from 'react-icons/si';
import { commentsData } from '../mock/commentsData';

interface CommentsFieldProps {
  type: 'latest' | 'popular';
}

interface YouTubeVimeoReply {
  id: number;
  username: string;
  profilePic: string;
  content: string;
  likes: number;
  dislikes: number;
}

interface XReply {
  id: number;
  username: string;
  profilePic: string;
  content: string;
  hearts: number;
  retweets: number;
}

const platformIcons: Record<string, JSX.Element> = {
  YouTube: <SiYoutube className="w-5 h-5 text-red-500" />,
  X: <SiX className="w-5 h-5 text-black dark:text-white" />,
  Vimeo: <SiVimeo className="w-5 h-5 text-cyan-500" />,
};

const isYouTubeVimeoReply = (
  reply: YouTubeVimeoReply | XReply,
): reply is YouTubeVimeoReply => 'likes' in reply;

const CommentsFieldDesktop: React.FC<CommentsFieldProps> = ({ type }) => {
  const [openReplies, setOpenReplies] = useState<{ [key: number]: boolean }>(
    {},
  );
  const icon =
    type === 'latest' ? (
      <CalendarClock className="w-6 h-6 mr-2 text-midnight-coal dark:text-soft-cloud" />
    ) : (
      <Sparkles className="w-6 h-6 mr-2 text-midnight-coal dark:text-soft-cloud" />
    );
  const title = type === 'latest' ? 'Latest' : 'Popular';

  const toggleReplies = (id: number) => {
    setOpenReplies((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="bg-soft-cloud dark:bg-darker-gray p-6 rounded-lg shadow-md mb-6 w-full min-w-[500px] max-w-[650px]">
      <div className="flex items-center mb-4">
        {icon}
        <h2 className="text-xl lg:text-2xl font-semibold text-midnight-coal dark:text-soft-cloud">
          {title}
        </h2>
      </div>
      <div className="h-[500px] overflow-y-auto pr-4 mr-[-16px]">
        {commentsData.map((comment) => (
          <div
            key={comment.id}
            className="flex items-start space-x-4 mb-8 pr-4"
          >
            <div className="flex-1">
              <div className="flex items-center mb-2">
                <img
                  src={comment.profilePic}
                  alt="Profile"
                  className="w-10 h-10 lg:w-12 lg:h-12 rounded-full mr-2"
                />
                <div className="flex items-center">
                  <p className="font-semibold text-midnight-coal dark:text-soft-cloud text-sm lg:text-base mr-2">
                    {comment.username}
                  </p>
                  {platformIcons[comment.platform] || (
                    <SiVimeo className="w-4 h-4 lg:w-5 lg:h-5 text-cyan-500" />
                  )}
                </div>
              </div>
              <p className="text-gray-700 dark:text-cadet-gray mb-2 break-words">
                {comment.content}
              </p>
              <div className="flex items-center space-x-4 mt-2 text-gray-600 dark:text-cadet-gray">
                <button className="border border-gray-500 rounded-full px-2 py-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:border-cadet-gray dark:hover:text-gray-300">
                  Reply
                </button>
                <button
                  onClick={() => toggleReplies(comment.id)}
                  className="flex items-center space-x-1 border border-gray-500 rounded-full px-2 py-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:border-cadet-gray dark:hover:text-gray-300"
                >
                  <span>{comment.replies.length} replies</span>
                  {openReplies[comment.id] ? (
                    <ChevronUp className="w-4 h-4" />
                  ) : (
                    <ChevronDown className="w-4 h-4" />
                  )}
                </button>
                {comment.platform !== 'X' && (
                  <>
                    <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                      <ThumbsUp className="w-6 h-6" />
                      <span>{comment.likes}</span>
                    </button>
                    <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                      <ThumbsDown className="w-6 h-6" />
                      <span>{comment.dislikes}</span>
                    </button>
                  </>
                )}
                {comment.platform === 'X' && (
                  <>
                    <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                      <Heart className="w-6 h-6" />
                      <span>{comment.hearts}</span>
                    </button>
                    <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                      <Repeat2 className="w-6 h-6" />
                      <span>{comment.retweets}</span>
                    </button>
                  </>
                )}
              </div>
              {openReplies[comment.id] && (
                <div className="mt-2 ml-6">
                  {comment.replies.map((reply) => (
                    <div
                      key={reply.id}
                      className="flex items-start space-x-2 mt-2"
                    >
                      <img
                        src={reply.profilePic}
                        alt="Profile"
                        className="w-10 h-10 rounded-full"
                      />
                      <div>
                        <p className="text-midnight-coal dark:text-soft-cloud font-medium">
                          {reply.username}
                        </p>
                        <p className="text-gray-700 dark:text-cadet-gray">
                          {reply.content}
                        </p>
                        <div className="flex items-center space-x-4 mt-2 text-gray-600 dark:text-cadet-gray">
                          {isYouTubeVimeoReply(reply) ? (
                            <>
                              <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                                <ThumbsUp className="w-6 h-6" />
                                <span>{reply.likes}</span>
                              </button>
                              <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                                <ThumbsDown className="w-6 h-6" />
                                <span>{reply.dislikes}</span>
                              </button>
                            </>
                          ) : (
                            <>
                              <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                                <Heart className="w-6 h-6" />
                                <span>{reply.hearts}</span>
                              </button>
                              <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                                <Repeat2 className="w-6 h-6" />
                                <span>{reply.retweets}</span>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <img
              src={comment.thumbnail}
              alt="Thumbnail"
              className="w-36 h-20 rounded object-cover shadow-lg border border-gray-300 dark:border-gray-700 flex-shrink-0"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const CommentsFieldMobile: React.FC<CommentsFieldProps> = ({ type }) => {
  const [openReplies, setOpenReplies] = useState<{ [key: number]: boolean }>(
    {},
  );
  const icon =
    type === 'latest' ? (
      <CalendarClock className="w-6 h-6 mr-2 text-midnight-coal dark:text-soft-cloud" />
    ) : (
      <Sparkles className="w-6 h-6 mr-2 text-midnight-coal dark:text-soft-cloud" />
    );
  const title = type === 'latest' ? 'Latest' : 'Popular';

  const toggleReplies = (id: number) => {
    setOpenReplies((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="bg-soft-cloud dark:bg-darker-gray p-4 sm:p-6 rounded-lg shadow-md mb-6 w-full min-w-[300px] max-w-[650px]">
      <div className="flex items-center mb-4">
        {icon}
        <h2 className="text-xl sm:text-2xl font-semibold text-midnight-coal dark:text-soft-cloud">
          {title}
        </h2>
      </div>
      <div className="h-[500px] overflow-y-auto pr-4 mr-[-16px]">
        {commentsData.map((comment) => (
          <div key={comment.id} className="flex flex-col mb-8 pr-4">
            <div className="flex items-center justify-between w-full mb-2">
              <div className="flex items-center space-x-2 flex-1 min-w-0 mr-4">
                <img
                  src={comment.profilePic}
                  alt="Profile"
                  className="w-10 h-10 rounded-full flex-shrink-0"
                />
                <div className="flex items-center min-w-0 flex-1">
                  <p className="text-midnight-coal dark:text-soft-cloud font-medium truncate mr-2">
                    {comment.username}
                  </p>
                  <div className="flex-shrink-0">
                    {platformIcons[comment.platform] || (
                      <SiVimeo className="w-5 h-5 text-cyan-500" />
                    )}
                  </div>
                </div>
              </div>
              <img
                src={comment.thumbnail}
                alt="Thumbnail"
                className="w-28 h-16 rounded object-cover shadow-lg border border-gray-300 dark:border-gray-700 flex-shrink-0"
              />
            </div>
            <p className="text-gray-700 dark:text-cadet-gray mb-2 break-words">
              {comment.content}
            </p>
            <div className="flex justify-start items-center space-x-2 sm:space-x-4 mt-2 text-gray-600 dark:text-cadet-gray">
              <button className="border border-gray-500 rounded-full px-2 py-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:border-cadet-gray dark:hover:text-gray-300">
                Reply
              </button>
              <button
                onClick={() => toggleReplies(comment.id)}
                className="flex items-center space-x-1 border border-gray-500 rounded-full px-2 py-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:border-cadet-gray dark:hover:text-gray-300"
              >
                <span>{comment.replies.length} replies</span>
                {openReplies[comment.id] ? (
                  <ChevronUp className="w-4 h-4" />
                ) : (
                  <ChevronDown className="w-4 h-4" />
                )}
              </button>
              {comment.platform !== 'X' && (
                <>
                  <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                    <ThumbsUp className="w-5 h-5 sm:w-6 sm:h-6" />
                    <span>{comment.likes}</span>
                  </button>
                  <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                    <ThumbsDown className="w-5 h-5 sm:w-6 sm:h-6" />
                    <span>{comment.dislikes}</span>
                  </button>
                </>
              )}
              {comment.platform === 'X' && (
                <>
                  <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                    <Heart className="w-5 h-5 sm:w-6 sm:h-6" />
                    <span>{comment.hearts}</span>
                  </button>
                  <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                    <Repeat2 className="w-5 h-5 sm:w-6 sm:h-6" />
                    <span>{comment.retweets}</span>
                  </button>
                </>
              )}
            </div>
            {openReplies[comment.id] && (
              <div className="mt-2 ml-6">
                {comment.replies.map((reply) => (
                  <div
                    key={reply.id}
                    className="flex items-start space-x-2 mt-2"
                  >
                    <img
                      src={reply.profilePic}
                      alt="Profile"
                      className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                    />
                    <div>
                      <p className="text-midnight-coal dark:text-soft-cloud font-medium">
                        {reply.username}
                      </p>
                      <p className="text-gray-700 dark:text-cadet-gray">
                        {reply.content}
                      </p>
                      <div className="flex justify-start items-center space-x-2 sm:space-x-4 mt-2 text-gray-600 dark:text-cadet-gray">
                        {isYouTubeVimeoReply(reply) ? (
                          <>
                            <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                              <ThumbsUp className="w-5 h-5 sm:w-6 sm:h-6" />
                              <span>{reply.likes}</span>
                            </button>
                            <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                              <ThumbsDown className="w-5 h-5 sm:w-6 sm:h-6" />
                              <span>{reply.dislikes}</span>
                            </button>
                          </>
                        ) : (
                          <>
                            <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                              <Heart className="w-5 h-5 sm:w-6 sm:h-6" />
                              <span>{reply.hearts}</span>
                            </button>
                            <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-cadet-gray dark:hover:text-gray-300">
                              <Repeat2 className="w-5 h-5 sm:w-6 sm:h-6" />
                              <span>{reply.retweets}</span>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export { CommentsFieldDesktop, CommentsFieldMobile };
