import { useState, useEffect } from 'react';
import { SiX, SiVimeo, SiYoutube } from 'react-icons/si';
import { Platform } from '../types/platforms';
import { fetchChannels } from './useChannels';
import { Channel } from '../types/channels';

const backendApiUrl = process.env.REACT_APP_API_URL;

const initialPlatforms: Platform[] = [
  {
    name: 'YouTube',
    connected: false,
    channels: [],
    IconComponent: SiYoutube,
  },
  {
    name: 'X',
    connected: false,
    channels: [],
    IconComponent: SiX,
  },
  {
    name: 'Vimeo',
    connected: false,
    channels: [],
    IconComponent: SiVimeo,
  },
];

export const usePlatforms = () => {
  const [platformData, setPlatformData] =
    useState<Platform[]>(initialPlatforms);

  useEffect(() => {
    const getChannels = async () => {
      const result = await fetchChannels();
      if (result) {
        const updatedPlatforms = platformData.map((platform) => ({
          ...platform,
          channels: result.channels.filter(
            (channel: Channel) => channel.platform === platform.name,
          ),
          connected: result.channels.some(
            (channel: Channel) => channel.platform === platform.name,
          ),
        }));
        setPlatformData(updatedPlatforms);
      }
    };

    getChannels();
  }, []);

  const handleConnect = (index: number) => {
    const updatedPlatforms = [...platformData];
    updatedPlatforms[index].connected = !updatedPlatforms[index].connected;

    if (!updatedPlatforms[index].connected) {
      updatedPlatforms[index].channels = [];
    }

    createNewChannel(updatedPlatforms[index].name);
    //setPlatformData(updatedPlatforms);
  };

  const createNewChannel = async (platformName: string) => {
    try {
      const response = await fetch(`${backendApiUrl}/channel/authNew`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          platform: platformName,
          name: `New ${platformName} Account`,
          description: `New ${platformName} Account`,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create new account');
      }

      const data = await response.json();

      const updatedPlatforms = [...platformData];
      const platformIndex = updatedPlatforms.findIndex(
        (p) => p.name === platformName,
      );

      if (platformIndex !== -1) {
        updatedPlatforms[platformIndex].connected = true;
        updatedPlatforms[platformIndex].channels.push(data.channel);
        setPlatformData(updatedPlatforms);
      }

      if (data.oauthUrl) {
        window.location.href = data.oauthUrl;
      }
    } catch (error) {
      console.error('Error creating new account:', error);
    }
  };

  const handleConnectMore = (index: number) => {
    const platformName = platformData[index].name;
    createNewChannel(platformName);
  };
  
  const disconnectChannel = async (
    platformIndex: number,
    channelIndex: number,
  ) => {
    try {
      const channelToDisconnect =
        platformData[platformIndex].channels[channelIndex];
      const response = await fetch(
        `${backendApiUrl}/channel?id=${channelToDisconnect.id}`,
        {
          method: 'DELETE',
          credentials: 'include',
        },
      );

      if (!response.ok) {
        throw new Error('Failed to disconnect channel');
      }

      const updatedPlatforms = [...platformData];
      updatedPlatforms[platformIndex].channels.splice(channelIndex, 1);

      if (updatedPlatforms[platformIndex].channels.length === 0) {
        updatedPlatforms[platformIndex].connected = false;
      }

      setPlatformData(updatedPlatforms);
    } catch (error) {
      console.error('Error disconnecting channel:', error);
    }
  };

  return {
    platformData,
    handleConnect,
    handleConnectMore,
    disconnectChannel,
    createNewChannel,
  };
};
