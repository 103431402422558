import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSidebar } from '../hooks/useSidebar';
import { useUser } from './useUserContext';
import { LogOut } from 'lucide-react';

const Sidebar: React.FC = () => {
  const { icons, getActiveStatus, isRouteValid } = useSidebar();
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();
  const { logout } = useUser();
  const logoutMenuRef = useRef<HTMLDivElement>(null);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        logoutMenuRef.current &&
        !logoutMenuRef.current.contains(event.target as Node)
      ) {
        setShowLogoutConfirm(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-soft-cloud dark:bg-darker-gray p-3 rounded-lg shadow-lg relative">
      <div className="flex flex-col space-y-4 text-cadet-gray relative">
        {icons.map(({ id, path, IconComponent }) => {
          const isActive = getActiveStatus(path);
          const isValid = isRouteValid(path);

          const content = (
            <div
              className={`group p-2 rounded-md relative ${
                isActive
                  ? 'bg-midnight-coal dark:bg-cadet-gray'
                  : isValid
                    ? 'hover:bg-midnight-coal dark:hover:bg-cadet-gray'
                    : 'opacity-50'
              } ${isValid ? 'cursor-pointer' : 'cursor-not-allowed'}`}
              onMouseEnter={() => setHoveredIcon(id)}
              onMouseLeave={() => setHoveredIcon(null)}
            >
              {isActive && isValid && (
                <div className="absolute -right-1.5 top-1.5 bottom-1.5 w-1 bg-midnight-coal dark:bg-cadet-gray rounded-r-full"></div>
              )}
              <IconComponent
                className={`w-6 h-6 ${
                  isActive
                    ? 'text-anti-flash-white dark:text-darker-gray'
                    : 'group-hover:text-anti-flash-white dark:group-hover:text-darker-gray text-medium-gray dark:text-cadet-gray'
                }`}
              />

              {/* Tooltip displayed to the right of the icon */}
              {hoveredIcon === id && isValid && (
                <div className="absolute left-12 top-1/2 transform -translate-y-1/2 px-2 py-1 text-xs bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-md z-50 whitespace-nowrap">
                  {id.charAt(0).toUpperCase() + id.slice(1)}
                </div>
              )}
            </div>
          );

          return isValid ? (
            <Link to={path} key={id}>
              {content}
            </Link>
          ) : (
            <div key={id}>{content}</div>
          );
        })}

        {/* Logout button */}
        <div
          className={`group p-2 rounded-md relative cursor-pointer ${
            showLogoutConfirm
              ? 'bg-midnight-coal dark:bg-cadet-gray'
              : 'hover:bg-midnight-coal dark:hover:bg-cadet-gray'
          }`}
          onMouseEnter={() => setHoveredIcon('logout')}
          onMouseLeave={() => setHoveredIcon(null)}
          onClick={() => setShowLogoutConfirm(!showLogoutConfirm)}
        >
          <LogOut
            className={`w-6 h-6 ${
              showLogoutConfirm
                ? 'text-anti-flash-white dark:text-darker-gray'
                : 'text-medium-gray dark:text-cadet-gray group-hover:text-anti-flash-white dark:group-hover:text-darker-gray'
            }`}
          />

          {hoveredIcon === 'logout' && !showLogoutConfirm && (
            <div className="absolute left-12 top-1/2 transform -translate-y-1/2 px-2 py-1 text-xs bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-md z-50 whitespace-nowrap">
              Logout
            </div>
          )}

          {/* Logout confirmation menu */}
          {showLogoutConfirm && (
            <div
              ref={logoutMenuRef}
              className="absolute left-14 top-1/2 transform -translate-y-1/2 bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg shadow-xl z-50 w-48 border border-cadet-gray dark:border-midnight-coal"
            >
              <p className="text-midnight-coal dark:text-cadet-gray mb-3 text-sm">
                Are you sure you want to logout?
              </p>
              <button
                onClick={handleLogout}
                className="w-full px-4 py-2 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-md hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-200 text-sm font-medium"
              >
                Confirm Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
