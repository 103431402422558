import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendPasswordResetEmail } from '../services/SuperTokensService';
import AuthPageLayout from '../components/AuthPageLayout';

const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(email);
      toast.success('Password reset instructions have been sent to your email');
      setEmail('');
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : 'Failed to send reset email',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthPageLayout title="Reset Password">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-midnight-coal dark:text-soft-cloud text-sm mb-2"
          >
            Email Address
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-md border border-cadet-gray dark:border-midnight-coal"
            placeholder="Enter your email"
            required
          />
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray p-3 rounded-md font-medium hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-200"
        >
          {isLoading ? 'Sending...' : 'Send Reset Instructions'}
        </button>

        <div className="text-center text-midnight-coal dark:text-soft-cloud">
          Remember your password?{' '}
          <Link to="/login" className="text-cadet-gray hover:underline">
            Log in
          </Link>
        </div>
      </form>
    </AuthPageLayout>
  );
};

export default ForgotPasswordPage;
