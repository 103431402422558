import React from 'react';

interface AuthPageLayoutProps {
  children: React.ReactNode;
  title: string;
}

const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({ children, title }) => {
  return (
    <div className="flex min-h-screen bg-anti-flash-white dark:bg-dark-gray">
      <div className="flex-grow relative hidden sm:block">
        <div className="absolute top-8 left-8 flex items-center">
          <img
            src="/logo.svg"
            alt="SubSynced Logo"
            className="w-12 h-12 mr-2"
            draggable="false"
          />
          <span className="text-2xl font-bold text-midnight-coal dark:text-soft-cloud">
            SubSynced
          </span>
        </div>
      </div>
      <div className="w-full sm:w-[480px] bg-soft-cloud dark:bg-darker-gray p-8 sm:p-12 flex flex-col justify-center min-h-screen relative overflow-hidden">
        <div className="absolute -top-24 -left-12 opacity-60 dark:opacity-20 pointer-events-none">
          <img src="/logo.svg" alt="" className="w-96 h-96" draggable="false" />
        </div>
        <h2 className="text-3xl font-bold text-midnight-coal dark:text-soft-cloud mb-8 relative z-10">
          {title}
        </h2>
        <div className="relative z-10">{children}</div>
      </div>
    </div>
  );
};

export default AuthPageLayout;
