import { UploadData } from '../types/uploadData';

const backendApiUrl = process.env.REACT_APP_API_URL;

const fetchWithCredentials = async (
  url: string,
  method: string,
  body?: any,
): Promise<any> => {
  const headers = new Headers();
  if (!(body instanceof FormData)) {
    headers.append('Content-Type', 'application/json');
  }

  const response = await fetch(url, {
    method: method,
    headers: headers,
    credentials: 'include',
    body: body instanceof FormData ? body : JSON.stringify(body),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(errorText || 'Server responded with an error');
  }

  return await response.json();
};

interface VideoData {
  videos: any[];
}

export const fetchUserVideos = async (page: number): Promise<any[]> => {
  try {
    const url = `${backendApiUrl}/video/uploaded-videos?page=${page}`;
    const videoData: VideoData = await fetchWithCredentials(url, 'GET');

    if (!videoData || videoData.videos.length === 0) {
      console.info(
        'No videos found. You have not uploaded any videos yet or reached the end of the list.',
      );
      return [];
    }

    return videoData.videos;
  } catch (error) {
    console.error('Failed to fetch videos:', error);
    return [];
  }
};

interface UploadResult {
  platform: string;
  success: boolean;
  error?: string;
}

interface UploadResult {
  results: UploadResult[];
}

export function convertToFormData(uploadData: UploadData): FormData {
  const formData = new FormData();

  formData.append('userId', uploadData.userId);
  if (uploadData.mediaFile instanceof File) {
    formData.append('mediaFile', uploadData.mediaFile);
  } else {
    throw new Error('mediaFIle must be an instance of File');
  }
  if (uploadData.thumbnail instanceof File) {
    formData.append('thumbnail', uploadData.thumbnail);
  }
  formData.append('title', uploadData.title);
  formData.append('description', uploadData.description);
  formData.append('channels', JSON.stringify(uploadData.channels));
  formData.append('status', uploadData.status);
  formData.append('error', uploadData.error || '');

  return formData;
}

export const uploadVideoToServer = async (
  upload: UploadData,
): Promise<void> => {
  try {
    console.log('UploadData', upload);
    const formData = convertToFormData(upload);
    const uploadRes: UploadResult = await fetchWithCredentials(
      `${backendApiUrl}/video/upload`,
      'POST',
      formData,
    );

    if (uploadRes && uploadRes.results) {
      uploadRes.results.forEach((result) => {
        if (result.success) {
          console.info(
            `${result.platform} Upload Successful. Your video has been successfully uploaded to ${result.platform}.`,
          );
        } else {
          console.error(
            `${result.platform} Upload Failed. ${result.error || 'An unknown error occurred'}`,
          );
        }
      });
    } else {
      console.error(
        'Video upload to server. No platforms were selected or an unknown error occurred.',
      );
    }
  } catch (error) {
    console.error('Server Upload failed:', error);
  }
};
