import React, { useState } from 'react';
import { Check } from 'lucide-react';

interface TitleAndDescriptionProps {
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  title: string;
  description: string;
}

const TitleAndDescription: React.FC<TitleAndDescriptionProps> = ({
  setTitle,
  setDescription,
  title,
  description,
}) => {
  const [isBoilerplateAppended, setIsBoilerplateAppended] =
    useState<boolean>(false);

  const handleCheckboxChange = () => {
    setIsBoilerplateAppended(!isBoilerplateAppended);
  };

  const handleEditBoilerplate = () => {
    alert('Edit boilerplate text clicked');
  };

  return (
    <div className="flex flex-col space-y-4">
      {/* Title Field */}
      <div className="mt-2 overflow-y-auto max-h-40">
        <label className="block text-midnight-coal dark:text-cadet-gray font-medium mb-2">
          Title
        </label>
        <input
          type="text"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          className="w-full p-2 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal rounded-md text-darker-gray dark:text-soft-cloud"
          placeholder="Add a video title..."
          style={{ minHeight: '2.5rem', maxWidth: '100%' }}
        />
      </div>

      {/* Description Field */}
      <div className="mt-4 overflow-y-auto max-h-40">
        <label className="block text-midnight-coal dark:text-cadet-gray font-medium mb-2">
          Description
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={4}
          className="w-full p-2 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal rounded-md resize-y overflow-hidden text-darker-gray dark:text-soft-cloud"
          placeholder="Add a video description..."
          style={{ maxWidth: '100%' }}
        ></textarea>
      </div>

      {/* Custom Checkbox and Edit Button */}
      <div className="mt-1 flex items-center">
        <div className="relative">
          <input
            type="checkbox"
            checked={isBoilerplateAppended}
            onChange={handleCheckboxChange}
            className="absolute opacity-0 h-0 w-0"
          />
          <div
            className={`w-5 h-5 flex items-center justify-center border-2 rounded-sm cursor-pointer ${
              isBoilerplateAppended
                ? 'bg-midnight-coal dark:bg-cadet-gray border-midnight-coal dark:border-cadet-gray'
                : 'bg-white dark:bg-darker-gray border-midnight-coal dark:border-cadet-gray'
            }`}
            onClick={handleCheckboxChange}
          >
            {isBoilerplateAppended && (
              <Check className="w-4 h-4 text-anti-flash-white dark:text-darker-gray" />
            )}
          </div>
        </div>
        <label className="ml-2 text-midnight-coal dark:text-cadet-gray">
          Append channel boilerplate text
        </label>
        <button
          onClick={handleEditBoilerplate}
          className="ml-4 text-midnight-coal dark:text-cadet-gray border border-midnight-coal dark:border-cadet-gray rounded-full px-2 py-1 text-sm"
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default TitleAndDescription;
