const backendApiUrl = process.env.REACT_APP_API_URL;
import { Video, VideoResponse } from '../types/videoData';

export async function fetchVideos(): Promise<Video[]> {
  try {
    const response = await fetch(`${backendApiUrl}/video`);
    if (!response.ok) {
      throw new Error('Failed to fetch videos');
    }
    const data: VideoResponse = await response.json();
    const videos = data.results.map(
      (item: any): Video => ({
        id: item.video.id || '',
        thumbnail: item.video.thumbnailUrl || '/defaultThumbnail.png',
        title: item.video.title || '',
        description: item.video.description || '',
        channelGroup:
          item.video.platforms && item.video.platforms.length > 0
            ? item.video.platforms[0]
            : '',
        uploadDate: new Date(item.video.createdAt) || new Date(),
        views: (
          parseInt(item.statistics.youtube?.viewCount || '0') +
          parseInt(item.statistics.vimeo?.plays || '0')
        ).toString(),
        likes: (
          parseInt(item.statistics.youtube?.likeCount || '0') +
          parseInt(item.statistics.vimeo?.likes || '0')
        ).toString(),
        dislikes: item.statistics.youtube?.dislikeCount || '',
        revenue: '', // Not provided in the statistics
        commentCount: (
          parseInt(item.statistics.youtube?.commentCount || '0') +
          parseInt(item.statistics.vimeo?.comments || '0')
        ).toString(),
        alt: item.video.alt || '',
        platforms: item.video.platforms || [],
        youtubeUrl: item.video.youtubeId
          ? `https://www.youtube.com/watch?v=${item.video.youtubeId}`
          : '',
        xUrl: item.video.xId
          ? `https://x.com/PopBase/status/${item.video.xId}`
          : '',
        vimeoUrl: item.video.vimeoId
          ? `https://vimeo.com/${item.video.vimeoId}`
          : '',
      }),
    );
    return videos;
  } catch (error) {
    console.error('Error fetching videos:', error);
    return [];
  }
}
