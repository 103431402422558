import React from 'react';
import { Upload, ChevronsRight } from 'lucide-react';

interface FoldOutHeaderProps {
  toggleMenu: () => void;
}

const FoldOutHeader: React.FC<FoldOutHeaderProps> = ({ toggleMenu }) => {
  return (
    <div className="flex items-center justify-between p-4 bg-anti-flash-white dark:bg-dark-gray rounded-tl-lg overflow-hidden">
      <h1 className="text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
        <Upload className="w-8 h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
        Upload
      </h1>
      <button
        onClick={toggleMenu}
        aria-label="Close menu"
        className="group flex items-center justify-center w-10 h-10 rounded-md cursor-pointer bg-anti-flash-white dark:bg-dark-gray hover:bg-midnight-coal dark:hover:bg-cadet-gray"
      >
        <ChevronsRight
          className={`w-8 h-8 text-midnight-coal dark:text-cadet-gray group-hover:text-anti-flash-white dark:group-hover:text-darker-gray`}
        />
      </button>
    </div>
  );
};

export default FoldOutHeader;
