import React from 'react';
import { Upload, ArrowUp } from 'lucide-react';

interface UploadButtonProps {
  handleUpload: () => void;
  hasVideos: boolean;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  handleUpload,
  hasVideos,
}) => {
  return (
    <div className="relative inline-block">
      <button
        onClick={handleUpload}
        className={`flex items-center justify-center bg-soft-cloud dark:bg-cadet-gray text-midnight-coal dark:text-darker-gray py-2 px-6 rounded-md shadow h-16 text-lg font-medium
                   transition-all duration-200 ease-in-out
                   hover:bg-midnight-coal hover:text-soft-cloud
                   dark:hover:bg-darker-gray dark:hover:text-cadet-gray
                   hover:shadow-md`}
      >
        <Upload className="w-8 h-8 mr-2" />
        Upload
      </button>
      {!hasVideos && (
        <>
          <div className="absolute top-full left-1/2 -translate-x-1/2 mt-1">
            <ArrowUp className="w-8 h-8 text-midnight-coal dark:text-cadet-gray" />
          </div>
          <div className="absolute top-full right-1/2 -mr-4 mt-9 px-3 py-1 text-base text-midnight-coal dark:text-cadet-gray bg-soft-cloud dark:bg-darker-gray rounded-lg shadow-sm whitespace-nowrap z-10">
            Upload your first video!
          </div>
        </>
      )}
    </div>
  );
};

export default UploadButton;
