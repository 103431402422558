import React, { useState, useRef, useEffect } from 'react';
import { Check, Info } from 'lucide-react';

const Advanced: React.FC = () => {
  const [activeInfo, setActiveInfo] = useState<string | null>(null);
  const [infoPosition, setInfoPosition] = useState<string>('left');

  // Refs for each info popup
  const infoRefs = {
    paidPromotion: useRef<HTMLDivElement>(null),
    deepFake: useRef<HTMLDivElement>(null),
    captions: useRef<HTMLDivElement>(null),
    commentsDisabled: useRef<HTMLDivElement>(null),
    ratingsDisabled: useRef<HTMLDivElement>(null),
    spamFilter: useRef<HTMLDivElement>(null),
    profanityFilter: useRef<HTMLDivElement>(null),
  };

  // Handle info click
  const handleInfoClick = (
    id: string,
    message: string,
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    if (activeInfo === id) {
      setActiveInfo(null); // Close popup if clicking the same icon
    } else {
      setActiveInfo(id); // Set active info popup
    }

    // Calculate position based on screen width and the element's position
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const padding = 20; // Minimum padding from the screen edges

      if (rect.right + 200 > window.innerWidth - padding) {
        setInfoPosition('right');
      } else if (rect.left < padding) {
        setInfoPosition('left');
      } else {
        setInfoPosition('center');
      }
    }
  };

  // Close the popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        activeInfo &&
        !infoRefs[activeInfo as keyof typeof infoRefs]?.current?.contains(
          event.target as Node,
        )
      ) {
        setActiveInfo(null); // Close popup if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeInfo]);

  const renderCustomCheckbox = (checked: boolean, onChange: () => void) => (
    <div className="relative">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="absolute opacity-0 h-0 w-0"
      />
      <div
        className={`w-5 h-5 flex items-center justify-center border-2 rounded-sm cursor-pointer ${
          checked
            ? 'bg-midnight-coal dark:bg-cadet-gray border-midnight-coal dark:border-cadet-gray'
            : 'bg-white dark:bg-darker-gray border-midnight-coal dark:border-cadet-gray'
        }`}
        onClick={onChange}
      >
        {checked && (
          <Check className="w-4 h-4 text-anti-flash-white dark:text-darker-gray" />
        )}
      </div>
    </div>
  );

  const renderInfoPopup = (
    id: string,
    message: string,
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    return (
      activeInfo === id && (
        <div
          ref={ref}
          className={`absolute bottom-full mb-2 p-2 w-48 bg-cadet-gray text-soft-cloud text-sm rounded-md shadow-lg ${
            infoPosition === 'left'
              ? 'left-0'
              : infoPosition === 'right'
                ? 'right-0'
                : 'left-1/2 -translate-x-1/2'
          }`}
        >
          {message}
        </div>
      )
    );
  };

  return (
    <div className="flex flex-col space-y-4 mt-6 relative">
      {/* Paid Promotion Checkbox */}
      <div className="relative flex items-center">
        {renderCustomCheckbox(false, () =>
          console.log('Paid promotion checkbox clicked'),
        )}
        <label className="ml-2 text-midnight-coal dark:text-cadet-gray">
          Video contains paid promotion
        </label>
        <div ref={infoRefs.paidPromotion} className="relative ml-2">
          <Info
            className="text-midnight-coal dark:text-cadet-gray cursor-pointer"
            onClick={() =>
              handleInfoClick(
                'paidPromotion',
                'This option indicates that the video contains paid promotion.',
                infoRefs.paidPromotion,
              )
            }
          />
          {renderInfoPopup(
            'paidPromotion',
            'This option indicates that the video contains paid promotion.',
            infoRefs.paidPromotion,
          )}
        </div>
      </div>

      {/* Deep Fake Checkbox */}
      <div className="relative flex items-center">
        {renderCustomCheckbox(false, () =>
          console.log('Deep fake checkbox clicked'),
        )}
        <label className="ml-2 text-midnight-coal dark:text-cadet-gray">
          Video contains altered content
        </label>
        <div ref={infoRefs.deepFake} className="relative ml-2">
          <Info
            className="text-midnight-coal dark:text-cadet-gray cursor-pointer"
            onClick={() =>
              handleInfoClick(
                'deepFake',
                'This option indicates that the video contains altered content, such as a deepfake.',
                infoRefs.deepFake,
              )
            }
          />
          {renderInfoPopup(
            'deepFake',
            'This option indicates that the video contains altered content, such as a deepfake.',
            infoRefs.deepFake,
          )}
        </div>
      </div>

      {/* Captions/Subtitles */}
      <div className="relative flex items-center space-x-2">
        <label className="text-midnight-coal dark:text-cadet-gray">
          Captions/subtitles
        </label>
        <div ref={infoRefs.captions} className="relative">
          <Info
            className="text-midnight-coal dark:text-cadet-gray cursor-pointer"
            onClick={() =>
              handleInfoClick(
                'captions',
                'Generate or upload captions or subtitles for this video.',
                infoRefs.captions,
              )
            }
          />
          {renderInfoPopup(
            'captions',
            'Generate or upload captions or subtitles for this video.',
            infoRefs.captions,
          )}
        </div>
        <button
          onClick={() => console.log('Generate clicked')}
          className="ml-4 text-midnight-coal dark:text-cadet-gray border border-midnight-coal dark:border-cadet-gray rounded-full px-2 py-1 text-sm"
        >
          Generate
        </button>
        <button
          onClick={() => console.log('Upload clicked')}
          className="ml-2 text-midnight-coal dark:text-cadet-gray border border-midnight-coal dark:border-cadet-gray rounded-full px-2 py-1 text-sm"
        >
          Upload
        </button>
      </div>

      {/* Comments & Ratings */}
      <div className="flex flex-col space-y-2">
        <div className="relative flex items-center">
          {renderCustomCheckbox(false, () =>
            console.log('Comments checkbox clicked'),
          )}
          <label className="ml-2 text-midnight-coal dark:text-cadet-gray">
            Comments are disabled
          </label>
          <div ref={infoRefs.commentsDisabled} className="relative ml-2">
            <Info
              className="text-midnight-coal dark:text-cadet-gray cursor-pointer"
              onClick={() =>
                handleInfoClick(
                  'commentsDisabled',
                  'Disable comments for this video.',
                  infoRefs.commentsDisabled,
                )
              }
            />
            {renderInfoPopup(
              'commentsDisabled',
              'Disable comments for this video.',
              infoRefs.commentsDisabled,
            )}
          </div>
        </div>

        <div className="relative flex items-center">
          {renderCustomCheckbox(false, () =>
            console.log('Ratings checkbox clicked'),
          )}
          <label className="ml-2 text-midnight-coal dark:text-cadet-gray">
            Ratings are disabled
          </label>
          <div ref={infoRefs.ratingsDisabled} className="relative ml-2">
            <Info
              className="text-midnight-coal dark:text-cadet-gray cursor-pointer"
              onClick={() =>
                handleInfoClick(
                  'ratingsDisabled',
                  'Disable ratings for this video.',
                  infoRefs.ratingsDisabled,
                )
              }
            />
            {renderInfoPopup(
              'ratingsDisabled',
              'Disable ratings for this video.',
              infoRefs.ratingsDisabled,
            )}
          </div>
        </div>

        <div className="relative flex items-center">
          {renderCustomCheckbox(false, () =>
            console.log('Spam filter checkbox clicked'),
          )}
          <label className="ml-2 text-midnight-coal dark:text-cadet-gray">
            Enable SubSynced spam filter
          </label>
          <div ref={infoRefs.spamFilter} className="relative ml-2">
            <Info
              className="text-midnight-coal dark:text-cadet-gray cursor-pointer"
              onClick={() =>
                handleInfoClick(
                  'spamFilter',
                  'Enable the SubSynced spam filter to manage spammy comments.',
                  infoRefs.spamFilter,
                )
              }
            />
            {renderInfoPopup(
              'spamFilter',
              'Enable the SubSynced spam filter to manage spammy comments.',
              infoRefs.spamFilter,
            )}
          </div>
        </div>

        <div className="relative flex items-center">
          {renderCustomCheckbox(false, () =>
            console.log('Profanity filter checkbox clicked'),
          )}
          <label className="ml-2 text-midnight-coal dark:text-cadet-gray">
            Enable SubSynced profanity filter
          </label>
          <div ref={infoRefs.profanityFilter} className="relative ml-2">
            <Info
              className="text-midnight-coal dark:text-cadet-gray cursor-pointer"
              onClick={() =>
                handleInfoClick(
                  'profanityFilter',
                  'Enable the SubSynced profanity filter to block inappropriate language in comments.',
                  infoRefs.profanityFilter,
                )
              }
            />
            {renderInfoPopup(
              'profanityFilter',
              'Enable the SubSynced profanity filter to block inappropriate language in comments.',
              infoRefs.profanityFilter,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advanced;
